import dayjs from "dayjs";
import { graphql } from "../../graphql";

export const AllConfQuery = graphql(`
  query AllConfigsQuery {
    product_config(
      where: {
        _and: {
          formula: { _is_null: false }
          relative_month: { _lte: 60, _gte: 0 }
        }
      }
    ) {
      formula
      product
      offset: relative_month
    }
  }
`);

export const AllConfigsSub = graphql(`
  subscription AllConfigsSub {
    product_config(where: { _and: { relative_month: { _lte: 60, _gte: 0 } } }) {
      formula
      product
      offset: relative_month
    }
  }
`);

export const ProductInfoQuery = graphql(`
  query ProductInfo {
    product {
      id
      artis_type
      summary_type
      eod_product_dep
    }
  }
`);

export const ProductInfoSub = graphql(`
  subscription ProductInfoSub {
    product {
      id
      artis_type
      summary_type
      eod_product_dep
    }
  }
`);

export const EodEntriesSub = graphql(`
  subscription eodEntries($eval_date: date!, $date: date!) {
    eod_entry(
      where: { evaluation_date: { _eq: $eval_date }, month: { _gte: $date } }
    ) {
      evaluation_date
      month
      product
      value
    }
  }
`);

export const LatestEodDateQuery = graphql(`
  query latestEODDate @cached {
    eod_entry(
      order_by: { evaluation_date: desc }
      distinct_on: evaluation_date
      limit: 1
    ) {
      evaluation_date
    }
  }
`);

export const currentDate = new Date();
export const currentDay = currentDate.toISOString().split("T")[0];
export const currentMonth = `${currentDate.getFullYear()}-${
  currentDate.getMonth() + 1
}-01`;

export const SharedInstsQuery = graphql(`
  query sharedInstrumentsQuery($current_month: date!) {
    shared_instrument(where: { month: { _gte: $current_month } }) {
      product
      edited_at
      edited_by
      month
      value
      folio_user {
        id
        username
        firstname
        lastname
      }
    }
  }
`);

export const SharedInstsSub = graphql(`
  subscription sharedInstruments(
    $current_date: timestamptz
    $current_month: date!
  ) {
    shared_instrument_stream(
      cursor: { initial_value: { edited_at: $current_date }, ordering: ASC }
      where: { month: { _gte: $current_month } }
      batch_size: 10000
    ) {
      product
      edited_at
      edited_by
      month
      value
      folio_user {
        id
        username
        firstname
        lastname
      }
    }
  }
`);

export function subtractFourYears() {
  // Subtract 1460 days (4 years) from the current date
  const fourYearsAgo = dayjs().subtract(1460, "day");
  // Return the instant in ISO 8601 string format
  return fourYearsAgo.toISOString();
}

export const LocalInstsSub = graphql(`
  subscription localInstruments(
    $latest_date: timestamptz!
    $current_month: date!
  ) {
    local_instrument(
      where: {
        edited_at: { _gte: $latest_date }
        month: { _gte: $current_month }
      }
    ) {
      product
      storage_type
      folio_user
      edited_at
      month
      value
    }
  }
  `);

export const SessionCheck = graphql(`
  subscription UserSession ($user: String!, $mobile: Boolean!) {
    session_by_pk(folio_user: $user, is_mobile: $mobile) {
     id last_seen
  }
}
`);

export const LogoutRequestCheck = graphql(`
  subscription CheckForLogout($user: String!, $context: String!, $since: timestamptz!) {
    logout_request(
      where: {
        _and: [
          { folio_user: { _eq: $user } },
          { context: { _in: ["all", "refresh", $context] } },
          { logout_requested_at: { _gt: $since } }
        ]
      }
    ) {
      logout_requested_at
    }
  }
`);

export const RequestLogoutMutation = graphql(`
  mutation RequestLogout($user: String!, $context: String!) {
    insert_logout_request_one(
      object: {
        folio_user: $user,
        context: $context
      }
    ) {
      context
    }
  }
`);

export const DeleteSessionAndLogoutRequest = graphql(`
  mutation DeleteSessionAndLogoutRequest($user: String!, $mobile: Boolean!, $context: String!) {
    update_folio_user_by_pk(
      pk_columns: { id: $user },
      _set: {
        logout_requested_at: "now()",
        last_seen: "now()"
      }
    ) {
      id
    }
    delete_session(
      where: {
        folio_user: { _eq: $user },
        is_mobile: { _eq: $mobile }
      }
    ) {
      affected_rows
    }
    delete_logout_request(
      where: {
        _and: [
          { folio_user: { _eq: $user } },
          { context: { _in: ["all", $context] } }
        ]
      }
    ) {
      affected_rows
    }
  }
`);

export const StartSessionMutation = graphql(`
  mutation StartSession($user: String!, $mobile: Boolean!, $session: String!) {
    insert_session_one(
      object: {
        folio_user: $user,
        is_mobile: $mobile,
        id: $session,
        last_seen: "now()"
      },
      on_conflict: {
        constraint: session_pkey,
        update_columns: [id, last_seen]
      }
    ) {
      id
      last_seen
    }
    delete_logout_request(
      where: { folio_user: { _eq: $user } }
    ) {
      affected_rows
    }
  }
`);
