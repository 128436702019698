import { useAuth0 } from "@auth0/auth0-react";
import {
  Box,
  MenuItem,
  MenuList,
  Select,
  Option,
  Typography,
  Alert,
  IconButton,
  Grid,
} from "@mui/joy";
import dayjs from "dayjs";
import type {
  CandlestickData,
  IChartApi,
  ISeriesApi,
  ITimeScaleApi,
  Time,
  UTCTimestamp,
} from "lightweight-charts";
import {
  type ComponentProps,
  type MutableRefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { LineSeries, TimeScale } from ".";
import { client } from "../../triplit/triplit";
import {
  Autocomplete,
  type TOption,
} from "../components/Autocomplete/Autocomplete";
import { ContextMenu } from "../components/ContextMenu";
import { TradingBottomPanel } from "./TradingBottomPanel";
import { CandlestickRoundedSeries } from "./components/canldestick-series-rounded";
import { Chart } from "./components/chart";
import { ImageWatermark } from "./components/image-watermarks";
import { useAutocomplete } from "../components/Autocomplete/hooks";
import {
  useChartProducts,
  useFetchHistoricalData,
  useHistoricalChartData,
  type TLiveChart,
  useLivelyUpdateMarketData,
  useResetChart,
  useMergeHistoricalAndLiveData,
  usePriceDifferences,
  type TPriceDifference,
} from "./hooks";
import { RoundedCandleSeries } from "./rounded-candles-series/rounded-candles-series";
import {
  bottomBarHeight,
  defaultIndicatorsState,
  genChartId,
  isMidnight,
  rightOffset,
  specificPeriods,
  type TIndicatorsState,
} from "./utils";
import { useThemeMode } from "../../shared/hooks";
import ReactDOMServer from "react-dom/server";
import { ArtisLogo } from "../../images/ArtisLogo";
import { liveChartsPeriods } from "../market-grid/periodHelpers";
import { ErrorBoundary } from "@sentry/react";
import toast from "react-hot-toast";
import { TooltipPrimitive } from "./tooltip-plugin/tooltip";
import { useIntervalEffect, useThrottledCallback } from "@react-hookz/web";
import relativeTime from "dayjs/plugin/relativeTime";
import { useEntity } from "@triplit/react";
import { GoEye, GoEyeClosed, GoPencil } from "react-icons/go";
import { compressIndicatorsState } from "../../utils/compressedStringify";
import { IndicatorEditModal } from "./components/IndicatorEditModal";
dayjs.extend(relativeTime);

function ProductSelectLiveChart({
  chartId,
  userId,
  defaultProductId,
  defaultPeriodFrom,
  defaultSampleTime,
}: {
  chartId: string;
  userId: string;
  defaultProductId?: string;
  defaultPeriodFrom?: string;
  defaultSampleTime?: string;
}) {
  const chartData = useEntity(client, "liveCharts", chartId);

  const products = useChartProducts();
  const defaultOption = products?.find((p) => p.value === defaultProductId);

  const useAutocompleteProps = useAutocomplete({
    multiple: false,
    defaultValue: defaultOption,
  });
  const periodOptions = liveChartsPeriods;

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        height: "min-content",
      }}
    >
      <Autocomplete
        {...useAutocompleteProps}
        multiple={false}
        placeholder={defaultOption?.label || "Select product"}
        options={products || []}
        onSelectValue={async (v: TOption) => {
          try {
            if (!v) {
              console.warn(
                "Deleting chart because non existing one selected",
                chartId,
              );
              await client.delete("liveCharts", chartId);
              return;
            }
            await client.insert("liveCharts", {
              id: chartId,
              productId: v.value,
              sampleTime: defaultSampleTime || "1m",
              indicatorsState: await compressIndicatorsState(
                defaultIndicatorsState,
              ),
              userId,
            });
          } catch (e) {
            console.error(e);
            toast.error("Error updating chart");
          }
        }}
      />
      <Select
        size="sm"
        defaultValue={defaultPeriodFrom || ""}
        value={chartData?.result?.periodFrom || defaultPeriodFrom || ""}
        onChange={async (_, v) => {
          if (!v) return;
          await client.update("liveCharts", chartId, (e) => {
            e.periodFrom = v;
          });
        }}
      >
        <Option value="">Select period</Option>
        {periodOptions.map((p) => (
          <Option key={p.value} value={p.value}>
            {p.label}
          </Option>
        ))}
      </Select>
    </Box>
  );
}

export function UnselectedTradingView({ chartIdx }: { chartIdx: string }) {
  const { user } = useAuth0();
  const userId = user?.sub;

  if (!userId) throw new Error("No userId in UnselectedTradingView");

  const chartId = genChartId({ chartIdx, userId });

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        pt: 10,
        height: "100%",
        backgroundColor: (theme) => theme.palette.background.artis,
      }}
    >
      <ProductSelectLiveChart chartId={chartId} userId={userId} />
    </Box>
  );
}

export function TradingView({ chartMetadata }: { chartMetadata: TLiveChart }) {
  const [actionType, setActionType] = useState<"foo" | "bar">();

  const timeScaleRef = useRef<ITimeScaleApi<Time> | null>(null);

  const ActionContent = useMemo(() => {
    switch (actionType) {
      case "foo":
        return <Box> foo </Box>;
      case "bar":
        return <Box> bar </Box>;
      default:
        return undefined;
    }
  }, [actionType]);

  const indicatorsState =
    chartMetadata.indicatorsState || defaultIndicatorsState;

  const [editModal, setEditModal] = useState<
    | {
        open: true;
        indicatorId: string;
      }
    | undefined
  >(undefined);

  const currentState = indicatorsState.find(
    (s) => s.id === editModal?.indicatorId,
  );

  return (
    <>
      {currentState && (
        <IndicatorEditModal
          open={!!editModal}
          onClose={() => setEditModal(undefined)}
          currentState={currentState}
          indicatorsState={indicatorsState}
          chartId={chartMetadata.id}
        />
      )}
      <Box
        sx={{
          position: "absolute",
          top: 5,
          left: 5,
          zIndex: 12,
          display: "flex",
          gap: 1,
          flexDirection: "column",
        }}
      >
        <ProductSelectLiveChart
          chartId={chartMetadata.id}
          userId={chartMetadata.userId}
          defaultProductId={chartMetadata.productId}
          defaultPeriodFrom={chartMetadata.periodFrom}
          defaultSampleTime={chartMetadata.sampleTime}
        />
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          {indicatorsState
            ?.sort((a, b) => a.id.localeCompare(b.id))
            .map((s) => (
              <Box key={s.id} sx={{ display: "flex", alignItems: "center" }}>
                <Typography
                  sx={{
                    fontSize: 12,
                    fontWeight: 500,
                    pr: 1,
                    color: s.color,
                  }}
                >{`${s.stateType} (${s.period})`}</Typography>
                <IconButton
                  onClick={async () => {
                    const newState = [
                      ...indicatorsState.filter((state) => state.id !== s.id),
                      {
                        ...s,
                        show: !s.show,
                      },
                    ] satisfies TIndicatorsState;
                    const compressedState =
                      await compressIndicatorsState(newState);
                    await client.update("liveCharts", chartMetadata.id, (e) => {
                      e.indicatorsState = compressedState;
                    });
                  }}
                >
                  {!s.show ? <GoEyeClosed /> : <GoEye />}
                </IconButton>
                <IconButton
                  onClick={() => {
                    setEditModal({
                      open: true,
                      indicatorId: s.id,
                    });
                  }}
                >
                  <GoPencil />
                </IconButton>
              </Box>
            ))}
        </Box>
      </Box>
      <ContextMenu
        onOpenChange={(open) => {
          if (!open) setActionType(undefined);
        }}
        trigger={
          <Box
            sx={{
              height: "100%",
              position: "relative",
              backgroundColor: (theme) => theme.palette.background.level1,
            }}
          >
            <Box sx={{ height: `calc(100% - ${bottomBarHeight})` }}>
              <ErrorBoundary
                fallback={
                  <Box
                    sx={{
                      height: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Typography>
                      Unexpected error in chart, try closing and reopening it
                    </Typography>
                  </Box>
                }
              >
                <TradingViewInner
                  chartMetadata={chartMetadata}
                  timeScaleRef={timeScaleRef}
                />
              </ErrorBoundary>
            </Box>
            <TradingBottomPanel chartMetadata={chartMetadata} />
          </Box>
        }
      >
        {ActionContent ?? (
          <MenuList
            component="div"
            variant="outlined"
            size="sm"
            sx={{
              boxShadow: "sm",
              flexGrow: 0,
              minWidth: 200,
              maxHeight: 240,
              overflow: "auto",
            }}
          >
            <MenuItem
              onClick={() => {
                timeScaleRef.current?.resetTimeScale();
                const escEvent = new KeyboardEvent("keydown", {
                  key: "Escape",
                });
                document.dispatchEvent(escEvent);
              }}
            >
              Reset
            </MenuItem>
          </MenuList>
        )}
      </ContextMenu>
    </>
  );
}
type TTHemeCharts = Record<
  "light" | "dark",
  {
    mode: "light" | "dark";
    candlestick: {
      upColor: string;
      downColor: string;
    };
    layout: ComponentProps<typeof Chart>["layout"];
    grid: ComponentProps<typeof Chart>["grid"];
  }
>;

const themes = {
  light: {
    mode: "light",
    candlestick: {
      upColor: "var(--upColor)",
      downColor: "var(--downColor)",
    },
    layout: {
      attributionLogo: false,
      background: { color: "#F2F2F2" },
      textColor: "#333333",
    },
    grid: {
      vertLines: { color: "#D4D4D4" },
      horzLines: { color: "#D4D4D4" },
    },
  },
  dark: {
    mode: "dark",
    candlestick: {
      upColor: "var(--upColor)",
      downColor: "var(--downColor)",
    },
    layout: {
      attributionLogo: false,
      background: { color: "#141528" },
      textColor: "#D4D4D4",
    },
    grid: {
      vertLines: { color: "#2E2E2E" },
      horzLines: { color: "#2E2E2E" },
    },
  },
} as const satisfies TTHemeCharts;

function generateLogoWatermark(mode: "dark" | "light") {
  const svgString = ReactDOMServer.renderToString(<ArtisLogo mode={mode} />);
  const src = `data:image/svg+xml;base64,${btoa(svgString)}`;
  return new ImageWatermark(src, {
    maxHeight: 100,
    maxWidth: 100,
    padding: 20,
    alpha: 0.9,
  });
}

function generateTooltip(mode: "dark" | "light") {
  return new TooltipPrimitive({
    theme: mode,
    tooltip: {
      followMode: "tracking",
    },
  });
}

function MovingAverageSeries({
  id,
  period,
  color,
  data,
}: TIndicatorsState[number] & { data: CandlestickData[] }) {
  const indicatorData = useMemo(() => {
    // aggregate data by taking the last <period> candles and calculating the average for each candle
    const aggregatedData = data.map((d, idx) => {
      if (idx < period) return null;
      const periodData = data.slice(idx - period, idx);
      const average = periodData.reduce((acc, d) => acc + d.close, 0) / period;
      return {
        time: d.time,
        value: average,
      };
    });
    return aggregatedData.filter(Boolean);
  }, [data, period]);
  return (
    <LineSeries lineWidth={1} reactive data={indicatorData} color={color} />
  );
}

function TradingViewInner({
  chartMetadata,
  timeScaleRef,
}: {
  chartMetadata: TLiveChart;
  timeScaleRef: MutableRefObject<ITimeScaleApi<Time> | null>;
}) {
  const chartRef = useRef<ISeriesApi<"Custom"> | null>(null);
  const chart = chartRef.current;
  const timeScale = timeScaleRef.current;
  const wrapperChartRef = useRef<IChartApi | null>(null);
  const oldestDateRef = useRef<dayjs.Dayjs | null>(null);
  const oldestDate = oldestDateRef.current;
  const setOldestDate = useCallback((date: dayjs.Dayjs) => {
    oldestDateRef.current = date;
  }, []);

  const {
    data: historicalData,
    error,
    fetchPreviousPage,
    fetchNextPage: fetchNext,
    isFetchingPreviousPage,
  } = useHistoricalChartData({
    chartMetadata,
    setOldestDate,
    oldestDate,
  });

  const { mode } = useThemeMode("liveChartsTheme");

  const latestHistoricalCandle = historicalData?.[historicalData.length - 1];

  const fetchNextPage = useThrottledCallback(
    () => {
      setTimeout(() => {
        const t = performance.now();
        fetchNext()
          .then(() => {
            console.log("fetched next page in ", performance.now() - t, "ms");
          })
          .catch((e) => {
            console.error("Error fetching next page", e);
          });
      }, 100);
    },
    [fetchNext],
    1000,
    true,
  );

  const currentCandle = useLivelyUpdateMarketData({
    latestHistoricalCandle,
    chartMetadata,
    chart,
    fetchNextPage,
  });

  const data = useMergeHistoricalAndLiveData(historicalData, currentCandle);

  const fetchHistoricalData = useFetchHistoricalData({
    fetchPreviousPage,
    chart,
    timeScale,
    productId: chartMetadata.productId,
    sampleTime: chartMetadata.sampleTime,
  });

  useEffect(() => {
    if (chartMetadata.sampleTime) {
      fetchHistoricalData();
    }
  }, [chartMetadata, fetchHistoricalData]);

  const resetChart = useResetChart(timeScale);

  const customSeriesView = useMemo(
    () => new RoundedCandleSeries({ rounded: false }),
    [],
  );

  useIntervalEffect(() => {
    if (data && data.length < 120) fetchHistoricalData();
  }, 1000);

  const latestHumanReadable = useMemo(() => {
    if (!oldestDate) return "";
    return `since ${oldestDate.fromNow()}`;
  }, [oldestDate]);

  const loaded = historicalData && data.length > 2;

  const priceDifferences = usePriceDifferences({
    currentCandle,
    historicalData,
    chartMetadata,
  });

  if (error && !historicalData) {
    return (
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <Alert variant="outlined" color="danger">
          {error.message}
        </Alert>
      </Box>
    );
  }

  return (
    <>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          right: 0,
          zIndex: 10,
          display: loaded ? "none" : "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          backgroundColor: themes[mode].layout.background.color,
        }}
      >
        <Alert variant="outlined" color="neutral">
          Loading...
        </Alert>
      </Box>
      <Chart
        ref={wrapperChartRef}
        onDblClick={() => {
          resetChart();
        }}
        autoSize
        {...themes[mode]}
        wrapperHeight="100%"
        timeScale={{
          rightOffset,
          secondsVisible: true,
          timeVisible: true,
          rightBarStaysOnScroll: true,
          tickMarkFormatter: (time: UTCTimestamp) => {
            if (!isMidnight(time)) return null;

            // time needs to be UTC
            const date = dayjs(time * 1000).utc();
            return date.format("ddd D MMM");
          },
        }}
      >
        {loaded && priceDifferences && (
          <TradingViewPriceDifferences
            priceDifferences={priceDifferences}
            mode={mode}
          />
        )}
        <CandlestickRoundedSeries
          ref={chartRef}
          view={customSeriesView}
          onSeriesReady={(api) => {
            const tooltip = generateTooltip(mode);
            const logoWatermark = generateLogoWatermark(mode);
            api.attachPrimitive(tooltip);
            api.attachPrimitive(logoWatermark);
            fetchHistoricalData();
          }}
          reactive
          data={data}
        />
        {chartMetadata.indicatorsState
          ?.filter((s) => s.show)
          .filter((s) => s.stateType === "MovingAverage")
          .map((s) => (
            <MovingAverageSeries key={s.id} data={data} {...s} />
          ))}
        <TimeScale
          onVisibleTimeRangeChange={(range) => {
            if (range) {
              fetchHistoricalData();
            }
          }}
          ref={timeScaleRef}
        />
      </Chart>
    </>
  );
}

function TradingViewPriceDifferences({
  priceDifferences,
  mode = "light",
}: {
  priceDifferences: TPriceDifference;
  mode?: "dark" | "light";
}) {
  const labels = specificPeriods.map((p) => p.label);

  const width = 160;
  const xs = 4;

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 62, // TimeScale height + sample time bar height + 2px margin
        left: 3, // Dockview 1px border + 2px margin
        zIndex: 11,
        pl: 1,
        background:
          mode === "dark" ? "rgba(255, 255, 255, 0.1)" : "rgba(0, 0, 0, 0.1)",
        borderRadius: "sm",
        ".positive": {
          color: "green",
        },
        ".negative": {
          color: "red",
        },
      }}
    >
      <Grid container columnSpacing={1} width={width}>
        {labels.map((label) => (
          <Grid xs={xs} key={label}>
            <Typography>{label}</Typography>
          </Grid>
        ))}
        {Object.values(priceDifferences).map((v, idx) => (
          <Grid key={labels[idx]} xs={xs}>
            <Typography
              slotProps={{
                root: {
                  className: v.className || "",
                },
              }}
            >
              {v.difference ? `${v.difference.toFixed(2)}` : "-"}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}
