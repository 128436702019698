import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  split,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { persistCache } from "apollo3-cache-persist";
import {
  createClient,
  type ClientOptions,
  type createClient as createWSClient,
} from "graphql-ws";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { jwtDecode } from "jwt-decode";
import { type JWT, type TEnv, getHasuraUrl } from "./globals";
import { getMainDefinition } from "@apollo/client/utilities";

let timedOut: ReturnType<typeof setTimeout>;
const client: ReturnType<typeof createWSClient> | undefined = undefined;
const timeoutDuration = 5_000;
export function wsClientOpts(url: string, getAccessTokenSilently: TGetToken) {
  const opts: ClientOptions = {
    url: url.replace("http", "ws"),
    keepAlive: timeoutDuration,
    connectionParams: async () => {
      const tok = await getAccessTokenSilently();

      return {
        headers: {
          authorization: `Bearer ${tok}`,
        },
      };
    },
    on: {
      opened: (e) => {
        console.log("ws opened", e);
      },
      closed: (e) => {
        console.log("ws closed", e);
      },
      ping: (received) => {
        if (!received /* sent */) {
          timedOut = setTimeout(() => {
            // a close event `4499: Terminated` is issued to the current WebSocket and an
            // artificial `{ code: 4499, reason: 'Terminated', wasClean: false }` close-event-like
            // object is immediately emitted without waiting for the one coming from `WebSocket.onclose`
            //
            // calling terminate is not considered fatal and a connection retry will occur as expected
            //
            // see: https://github.com/enisdenjo/graphql-ws/discussions/290
            client?.terminate();
          }, timeoutDuration);
        }
      },
      pong: (received) => {
        if (received) {
          clearTimeout(timedOut);
        }
      },
    },
  };
  return opts;
}

type TGetToken = () => Promise<string | undefined>;

async function getRoleOverrideHeader(getToken: TGetToken, isApp: boolean) {
  const token = await getToken();
  const claims = token ? jwtDecode<JWT>(token) : undefined;
  if (!claims) {
    throw new Error("No claims found in token");
  }

  const hasuraClaims = claims?.["https://hasura.io/jwt/claims"];
  const roles = hasuraClaims?.["x-hasura-allowed-roles"];
  const adminRoles = ["umi-internal-write"];

  for (const role of adminRoles) {
    if (roles?.includes(role) && !isApp) {
      return {
        "x-hasura-role": role,
      };
    }
  }
  return {};
}

export function createApolloClient(
  env: TEnv,
  getToken: TGetToken,
  isApp: boolean,
) {
  const cache = new InMemoryCache({
    typePolicies: {
      Subscription: {
        fields: {
          permission: {
            merge(_existing, incoming) {
              return incoming;
            },
          },
        },
      },
    },
  });
  const cacheKey = `artis-${isApp ? "app-" : "admin-"}${env}`;
  persistCache({
    cache,
    key: cacheKey,
    storage: window.localStorage,
  });

  const hasuraUrl = getHasuraUrl(env);

  const httpLink = createHttpLink({
    uri: hasuraUrl,
  });

  const authLink = setContext(async (_, { headers }) => {
    const roleOverrideHeader = await getRoleOverrideHeader(getToken, isApp);
    const token = await getToken();

    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : "",
        ...roleOverrideHeader,
      },
    };
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: hasuraUrl.replace("https", "wss").replace("http", "ws"),
      connectionParams: async () => {
        const token = await getToken();
        const roleOverrideHeader = await getRoleOverrideHeader(getToken, isApp);

        return {
          headers: {
            authorization: token ? `Bearer ${token}` : "",
            ...roleOverrideHeader,
          },
        };
      },
    }),
  );

  const link = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink,
  );

  const client = new ApolloClient({
    link: authLink.concat(link),
    defaultOptions: {
      watchQuery: {
        refetchWritePolicy: "overwrite",
        returnPartialData: true,
        errorPolicy: "all",
      },
    },
    cache,
    connectToDevTools:
      process.env.NODE_ENV === "development" || import.meta.env.DEV,
  });

  if (client) {
    return client;
  }
  throw new Error(`No client found for env: ${env}`);
}

export type TApolloClient = ApolloClient<object>;
