import { graphql } from "../../graphql";

export const ProductFragmentGrid = graphql(`
  fragment ProductFragmentGrid on product {
    id
    artis_type
    code
    commodity_group
    description
    eod_product_dep
    geographical_region
    has_shared_cell
    logical_code
    maturity
    name
    package
    product_type
    summary_type
    uom
    packageByPackage {
      package_type
      name
      permissions(limit: 1, where: { folio_user: { _eq: $folioUser } }) {
        permission
        folio_user
      }
      sourceBySource {
        name
        logo
        exchange {
          code
        }
      }
    }
    commodityGroupByCommodityGroup {
      parent
      commodity_group: value
      commodity_parent_group {
        commodity_parent_group: value
      }
    }
    geographicalRegionByGeographicalRegion {
      geographical_region: value
    }
    product_configs(order_by: { relative_month: asc }) {
			id
      formula
      product
      relative_month
    }
    product_global_dependencies {
      global_product     
    }
  }
`);

export const gridProductsByIds = graphql(`
  query GridProductsByIds($ids: [uuid!]!, $folioUser: String!) {
    product(where: { id: { _in: $ids } }) {
      ...ProductFragmentGrid
    }
  }
`);

export const productByIdQuery = graphql(`
  query ProductById($id: uuid!, $folioUser: String!) {
    product_by_pk(id: $id) {
      ...ProductFragmentGrid
    }
  }
`);

export const allProducts = graphql(`
  query AllProducts($folioUser: String!) {
    product {
      ...ProductFragmentGrid
    }
  }
`);

export const productGlobalDependencies = graphql(`
  query ProductGlobalDependencies {
    product_global_dependencies {
      global_product
      product
    }
  }
`);
