import { withAuthenticationRequired } from "@auth0/auth0-react";
import {
  RouterProvider,
  createRoute,
  createRouter,
  redirect,
} from "@tanstack/react-router";
import { ErrorBoundary } from "react-error-boundary";
import { Fragment } from "react/jsx-runtime";
import {
  auditLogRoute,
  consoleRoute,
  orgsRoute,
  createOrganisationRoute,
  reportsEditRoute,
  reportsExchangesRoute,
  reportsGlobalPermissionsRoute,
  reportsRoute,
  rootAdminRoute,
  singleOrgAgreementsRoute,
  singleOrgCreateUserRoute,
  singleOrgEditUserRoute,
  singleOrgGlobalPermissionsRoute,
  singleOrgPackagesRoute,
  singleOrgCreatePackageRoute,
  singleOrgPermissionsRoute,
  singleOrgProductsRoute,
  singleOrgRoute,
  singleOrgSingleUserPackagesRoute,
  singleOrgSingleUserProductsRoute,
  singleOrgUsersRoute,
} from "./admin/adminRoutes";
import { AuthProvider } from "./auth/Components";
import { rootRoute } from "./baseRoutes";
import {
  managePagesRoute,
  marketHomeRoute,
  marketPageRoute,
  rootWebappRoute,
  tradingChartRoute,
} from "./webapp/Routes";
import Loading from "./webapp/components/Loading";

export const indexRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/",
  beforeLoad: () => {
    throw redirect({
      to: "/app/market",
    });
  },
});

export const catchAllRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "/*",
  beforeLoad: () => {
    throw redirect({
      to: "/app/market",
    });
  },
});

const routeTree = rootRoute.addChildren([
  indexRoute,
  rootWebappRoute.addChildren([
    marketHomeRoute,
    marketPageRoute,
    managePagesRoute,
    tradingChartRoute,
  ]),
  rootAdminRoute.addChildren([
    orgsRoute,
    createOrganisationRoute,
    singleOrgRoute.addChildren([
      singleOrgPermissionsRoute,
      singleOrgGlobalPermissionsRoute,
      singleOrgUsersRoute,
      singleOrgCreateUserRoute,
      singleOrgEditUserRoute,
      singleOrgPackagesRoute,
      singleOrgCreatePackageRoute,
      singleOrgSingleUserPackagesRoute,
      singleOrgProductsRoute,
      singleOrgSingleUserProductsRoute,
      singleOrgAgreementsRoute,
    ]),
    consoleRoute,
    auditLogRoute,
    reportsRoute.addChildren([
      reportsExchangesRoute,
      reportsEditRoute,
      reportsGlobalPermissionsRoute,
    ]),
  ]),
  catchAllRoute,
]);

const router = createRouter({
  routeTree,
  defaultPendingComponent: () => <Loading showLogo syncing={false} />,
  defaultErrorComponent: () => {
    return <div>TODO Error Component</div>;
  },
});

declare module "@tanstack/react-router" {
  interface Register {
    router: typeof router;
  }
}

const AuthRedirect = withAuthenticationRequired(Fragment, {
  loginOptions: {
    appState: {
      returnTo: window.location.pathname,
    },
  },
});

export function App() {
  return (
    <ErrorBoundary
      fallback={
        <div>
          <h1>Something went wrong.</h1>
        </div>
      }
    >
      <AuthProvider>
        <AuthRedirect>
          <RouterProvider router={router} />
        </AuthRedirect>
      </AuthProvider>
    </ErrorBoundary>
  );
}
