import { Autocomplete } from "@mui/joy";
import { memo } from "react";
import { type TUserRow, useSingleUserById } from "../users";
import type { CustomCellEditorProps } from "ag-grid-react";
import { client } from "../../triplit/triplit";
import { Unstable_Popup as BasePopup } from "@mui/base/Unstable_Popup";
import { Stack } from "@mui/joy";
import { FaChevronDown } from "react-icons/fa";
import { useGridCustomSelect } from "../hooks/gridHooks";
import { parseRoles, upsertExistingUser } from "../../utils/users";
import { useAuth0 } from "@auth0/auth0-react";
import { roles } from "../../globals";
import { formatEdits } from "./floatingSaveHelpers";
import { editableUserKeys } from "../stores/orgStore/usersEdits";
import { roleToLabel } from "./multiSelectHelpers";

export function RolesSelect({
  sx,
  autoFocus,
  usersRow,
  onChange,
  onBlur,
}: {
  sx?: Parameters<typeof Autocomplete>[0]["sx"];
  autoFocus?: boolean;
  onBlur?: Parameters<typeof Autocomplete>[0]["onBlur"];
  onChange?: Parameters<typeof Autocomplete>[0]["onChange"];
  usersRow: Pick<TUserRow, "id" | "roles">;
}) {
  const userId = usersRow.id.toString();

  const serverData = useSingleUserById(userId);

  const selectedRoles = usersRow.roles;
  const auth0 = useAuth0();

  return (
    <Autocomplete
      sx={sx}
      autoFocus={autoFocus}
      multiple
      isOptionEqualToValue={(option, value) => {
        // legacy roles we don't want to show in options
        const extraRoles = ["admin", "user-admin"];
        return option === value || extraRoles.includes(value);
      }}
      filterSelectedOptions
      // opening dropdown on mount doesn't work properly
      // and isn't important enough to spend time on
      // openOnFocus
      handleHomeEndKeys
      options={roles}
      getOptionLabel={roleToLabel}
      value={selectedRoles}
      onBlur={onBlur}
      onChange={async (e, newRoles, reason, details) => {
        onChange?.(e, newRoles, reason, details);
        const existing = await client.fetchById("pendingEditUser", userId);

        if (existing) {
          const oldData = {
            ...serverData,
            roles: parseRoles(serverData?.roles),
          };

          const edits = formatEdits({
            editableKeys: editableUserKeys,
            editedRow: { ...existing, roles: new Set(newRoles) },
            dbData: oldData,
          });

          if (edits?.length === 1 && edits[0]?.key === "roles") {
            const editedRoles = edits[0];
            if (
              editedRoles?.addedValues?.length === 0 &&
              editedRoles?.removedValues?.length === 0
            ) {
              client.delete("pendingEditUser", userId);
              return;
            }
          }
        }

        upsertExistingUser({
          key: "roles",
          value: new Set(newRoles),
          updatePendingUser: () => {
            if (reason === "removeOption") {
              const roleRemoved = details?.option;
              if (!roleRemoved) return;
              client.update("pendingEditUser", userId, (row) => {
                row.roles?.delete(roleRemoved);
              });
            }
            if (reason === "clear") {
              client.update("pendingEditUser", userId, (row) => {
                // can't clear all roles, so default to just user
                row.roles = new Set(["user"]);
              });
            } else {
              client.update("pendingEditUser", userId, (row) => {
                row.roles = new Set(newRoles);
              });
            }
          },
          userId,
          pendingEditUser: existing || undefined,
          updatedBy: auth0.user?.sub || null,
        });
      }}
    />
  );
}

export const UserRolesMultiSelectAgGridEditor = memo(
  (props: CustomCellEditorProps<TUserRow, TUserRow["roles"]>) => {
    const { anchorRef } = useGridCustomSelect(props);

    return (
      <>
        <Stack
          ref={anchorRef}
          width="100%"
          height="100%"
          flexDirection={"row"}
          justifyContent={"space-between"}
          alignItems={"center"}
          gap={1}
          sx={{
            borderRadius: 0,
            pl: 1,
            pr: 1,
          }}
        >
          Select roles
          <FaChevronDown />
        </Stack>

        <BasePopup open={true} anchor={anchorRef?.current}>
          <RolesSelect
            sx={{
              width: props.column.getActualWidth(),
            }}
            autoFocus
            usersRow={props.data}
          />
        </BasePopup>
      </>
    );
  },
);
