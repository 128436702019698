import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormLabel,
  Tooltip,
} from "@mui/joy";
import {
  createOrganisationMutation,
  createSourceMutation,
  deleteSourceMutation,
  usePendingOrganisation,
} from ".";
import { Loading } from "../components/Loading";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "@tanstack/react-router";
import { useFloatingSave } from "../hooks/useFloatingSave";
import { useAuditLoggerFactory } from "../audit";
import { z } from "zod";
import { Controller, useForm } from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { client } from "../../triplit/triplit";
import toast from "react-hot-toast";
import { InputField } from "../components/Form";
import type { SxProps } from "@mui/joy/styles/types";
import { useEntity } from "@triplit/react";
import { isEmpty, isNullish } from "remeda";
import { FloatingContainer, UndoSaving } from "../components/FloatingSave";
import { useMutation } from "@apollo/client";
import { Product_Source_EnumSchema } from "../../__generated__/gql-validation/schemas";
import type { TPendingOrganisation } from "../../triplit/schema";

const organisationValidation = z.object({
  name: z.string().min(1),
  description: z.string().nullable(),
  alias: z.string().min(1).max(4),
  logo: z.string().nullable(),
  allowedGlobalPackages: z.boolean().nullable(),
  needsHistoricalData: z.boolean().nullable(),
});

type FormPendingOrganisation = z.infer<typeof organisationValidation>;
type TEditablePendingOrganisationKeys = keyof FormPendingOrganisation;

const inputWidth = 260;
const inputSx = {
  width: inputWidth,
} satisfies SxProps;

function CreateOrganisationForm({
  pendingOrganisation,
}: {
  pendingOrganisation: FormPendingOrganisation | undefined;
}) {
  const { tempOrgId: rowId } = usePendingOrganisation();

  const { result: existingPendingOrganisation } = useEntity(
    client,
    "pendingOrganisation",
    rowId,
  );

  const auth0 = useAuth0();

  const navigate = useNavigate({ from: "/admin/orgs/new/$id" });

  const [executeCreateSourceMutation] = useMutation(createSourceMutation);
  const [executeDeleteSourceMutation] = useMutation(deleteSourceMutation);
  const [executeCreateOrganisationMutation] = useMutation(
    createOrganisationMutation,
  );

  const { floatingSaveFn, cancelSaveFn, isWaiting } = useFloatingSave();

  const auditFactory = useAuditLoggerFactory();

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormPendingOrganisation>({
    defaultValues: pendingOrganisation,
    resolver: zodResolver(organisationValidation),
  });

  const cancelCreation = () => {
    client.delete("pendingOrganisation", rowId);
    navigate({
      to: "/admin/orgs",
      search: true,
    });
  };

  function onSubmit({
    name,
    description,
    alias,
    logo,
    allowedGlobalPackages,
    needsHistoricalData,
  }: FormPendingOrganisation) {
    if (!name || !alias) return;

    floatingSaveFn(async () => {
      const audit = auditFactory({
        actionName: "createOrganisation",
        clearanceLevel: "umi-internal-write",
      });

      audit.start(
        `Creating organisation: ${name} -> ${description} - ${alias} ${logo} allowedGlobalPackages: ${allowedGlobalPackages}, needsHistoricalData: ${needsHistoricalData}`,
      );

      try {
        const sourceVariables = {
          name,
          source_type: Product_Source_EnumSchema.Values.organisation,
          description: description ?? "",
          alias,
          logo: logo ?? "",
        };

        console.log("Creating source with variables: ", sourceVariables);

        const sourceMutation = executeCreateSourceMutation({
          variables: sourceVariables,
        });

        const sourceRes = await toast.promise(sourceMutation, {
          loading: "Creating source...",
          success: (result) => {
            return `Source created (ID: ${result.data?.insert_source_one?.id}).`;
          },
          error: (error) => {
            if (error instanceof Error) {
              return error.message;
            }

            return "Error creating source.";
          },
        });

        const source = sourceRes.data?.insert_source_one?.id;

        if (isNullish(source)) {
          throw new Error("Failed to create source.");
        }

        console.log("Source created with id: ", source);

        const organisationVariables = {
          source,
          is_allowed_global_packages: Boolean(allowedGlobalPackages),
          needs_historical_data: Boolean(needsHistoricalData),
        };

        console.log(
          "Creating organisation with variables: ",
          organisationVariables,
        );

        const organisationRes = executeCreateOrganisationMutation({
          variables: organisationVariables,
        });

        await toast.promise(organisationRes, {
          loading: "Creating organisation...",
          success: (result) => {
            const orgId = result.data?.insert_organisation_one?.id;

            if (isNullish(orgId)) {
              throw new Error("Failed to create organisation.");
            }

            audit.success();
            client.delete("pendingOrganisation", rowId);

            navigate({
              to: "/admin/orgs",
            });

            return `Organisation created (ID: ${orgId}).`;
          },
          error: (error) => {
            executeDeleteSourceMutation({
              variables: {
                id: source,
              },
            });

            audit.error("Error creating organisation.");
            if (error instanceof Error) {
              return error.message;
            }

            return "Error creating organisation.";
          },
        });
      } catch (e) {
        audit.error("Error creating organisation.");
        console.error({ error: e });
        return Promise.reject([{ error: e?.toString() }]);
      }
    });
  }

  const onBlurInput = async <T extends TEditablePendingOrganisationKeys>(
    k: T,
    v: TPendingOrganisation[T],
  ) => {
    const existing = await client.fetchById("pendingOrganisation", rowId);

    if (!existing) {
      return client.insert("pendingOrganisation", {
        id: rowId,
        orgId: rowId,
        updatedBy: auth0.user?.sub || null,
        name: "",
        description: null,
        alias: "",
        logo: null,
        allowedGlobalPackages: null,
        needsHistoricalData: null,
        [k]: v,
      });
    }

    client.update("pendingOrganisation", rowId, (row) => {
      row[k] = v;
    });
  };

  const defaultPendingOrganisation = {
    id: rowId,
    orgId: rowId,
    updatedBy: auth0.user?.sub || null,
    name: "",
    description: null,
    alias: "",
    logo: null,
    allowedGlobalPackages: null,
    needsHistoricalData: null,
  };

  return (
    <Box sx={{ pb: 10 }}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Box sx={{ p: 4, gap: 4, display: "flex" }}>
          <Tooltip title="Name of the organisation" arrow>
            <InputField
              label="name"
              sx={inputSx}
              {...register("name", {
                onBlur: (e) => {
                  const val = e.target.value;
                  onBlurInput("name", val);
                },
              })}
              error={errors.name}
            />
          </Tooltip>
          <Tooltip
            title="Description of the organisation, for our internal use"
            arrow
          >
            <InputField
              label="description"
              sx={inputSx}
              {...register("description", {
                onBlur: (e) => {
                  const val = e.target.value;
                  onBlurInput("description", val);
                },
              })}
              error={errors.description}
            />
          </Tooltip>
          <Tooltip
            title="Short alias for organisation; used in status dropdown; max 4 char."
            arrow
          >
            <InputField
              label="alias"
              sx={inputSx}
              {...register("alias", {
                onBlur: (e) => {
                  const val = e.target.value;
                  onBlurInput("alias", val);
                },
              })}
              error={errors.alias}
            />
          </Tooltip>
        </Box>
        <Box sx={{ p: 4, gap: 4, display: "flex", alignItems: "flex-start" }}>
          <Tooltip
            title="URL of organisation's logo image file; used in status dropdown"
            arrow
          >
            <InputField
              sx={inputSx}
              label="logo"
              {...register("logo", {
                onBlur: (e) => {
                  const val = e.target.value;
                  onBlurInput("logo", val);
                },
              })}
              error={errors.logo}
            />
          </Tooltip>
          <Box
            sx={{
              display: "flex",
              gap: 4,
              alignItems: "flex-end",
            }}
          >
            <Tooltip
              title="Allow organisation to globalise its packages?"
              arrow
            >
              <FormControl
                sx={{
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <FormLabel>Allowed Global Packages</FormLabel>
                <Controller
                  control={control}
                  name="allowedGlobalPackages"
                  render={({ field }) => (
                    <Checkbox
                      size="lg"
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (existingPendingOrganisation) {
                          client.update("pendingOrganisation", rowId, (row) => {
                            row.allowedGlobalPackages = e.target.checked;
                          });
                        } else {
                          client.insert("pendingOrganisation", {
                            ...defaultPendingOrganisation,
                            allowedGlobalPackages: e.target.checked,
                          });
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
            <Tooltip title="Save this organisation's calculated data?" arrow>
              <FormControl
                sx={{
                  gap: 0.5,
                  alignItems: "center",
                }}
              >
                <FormLabel>Needs Historical Data</FormLabel>
                <Controller
                  control={control}
                  name="needsHistoricalData"
                  render={({ field }) => (
                    <Checkbox
                      size="lg"
                      onChange={(e) => {
                        field.onChange(e.target.checked);
                        if (existingPendingOrganisation) {
                          client.update("pendingOrganisation", rowId, (row) => {
                            row.needsHistoricalData = e.target.checked;
                          });
                        } else {
                          client.insert("pendingOrganisation", {
                            ...defaultPendingOrganisation,
                            needsHistoricalData: e.target.checked,
                          });
                        }
                      }}
                    />
                  )}
                />
              </FormControl>
            </Tooltip>
          </Box>
        </Box>

        {!isEmpty(pendingOrganisation || {}) && (
          <FloatingContainer>
            <Box
              sx={{
                display: "flex",
                gap: 1,
              }}
            >
              <Button
                color="warning"
                type="button"
                disabled={isWaiting}
                onClick={cancelCreation}
              >
                Cancel
              </Button>
              {isWaiting ? (
                <UndoSaving cancelSaveFn={cancelSaveFn} />
              ) : (
                <Button
                  type="submit"
                  color="success"
                  onClick={() => {
                    if (!pendingOrganisation) return;
                    const {
                      name,
                      description,
                      alias,
                      logo,
                      allowedGlobalPackages,
                      needsHistoricalData,
                    } = pendingOrganisation;
                    onSubmit({
                      name,
                      description,
                      alias,
                      logo,
                      allowedGlobalPackages,
                      needsHistoricalData,
                    });
                  }}
                >
                  Create Organisation
                </Button>
              )}
            </Box>
          </FloatingContainer>
        )}
      </form>
    </Box>
  );
}

export function CreateOrganisation() {
  const { pendingOrganisation, fetchingLocal } = usePendingOrganisation();

  if (fetchingLocal) return <Loading />;

  return (
    <Box>
      <CreateOrganisationForm pendingOrganisation={pendingOrganisation} />
    </Box>
  );
}
