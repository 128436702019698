import type React from "react";
import { useState } from "react";
import { Box, Typography, FormControl, Checkbox, Button } from "@mui/joy";
import { ArtisLogo } from "../../images/ArtisLogo";
import { useApolloClient } from "@apollo/client";
import { amendExchangeUsage } from "./hooks";
import { useCurrentUserOrg, useUserId } from "../../auth";
import { licenseAgreementZIndex } from "../globals";

export const agreementTypes = [
  "CME_NYMEX",
  "ICE_FUTURES_EUROPE",
  "ICE_FUTURES_ABU_DHABI",
  "ICE_ENDEX",
  "IDS",
  "TP_ICAP",
  "ARTIS_XC",
] as const;

export type TAgreementType = (typeof agreementTypes)[number];

export function isAgreementType(value: string): value is TAgreementType {
  return (agreementTypes as readonly string[]).includes(value);
}

type AgreementProps = {
  username: string | undefined;
  companyName?: string;
};

type TBuildCopyProps = {
  username: string | undefined;
  companyName?: string;
  agreementType: TAgreementType;
};

const BuildCopyCME = ({ username, companyName }: AgreementProps) => (
  <>
    <header>
      <Typography level="h3">
        CME DATA – SUBSCRIBER’S USE OF INFORMATION
      </Typography>
    </header>
    <div className="page-body">
      <br />
      <p>Subscriber: {`${username} of company ${companyName}`}</p>
      <br />
      <p>
        a) must not redistribute Information outside of Subscriber Group,
        provided that Subscriber may distribute limited extracts of Information
        on an occasional basis to its customers subject to Information Policies,
        provided that such information:
      </p>
      <br />
      <p>
        (i) consists of one value per tenor per instrument with no other depth
        of book;
      </p>
      <br />
      <p>(ii) is provided manually;</p>
      <br />
      <p>and (iii) is provided on a push basis only.</p>
      <br />
      <p>
        CME may determine in its sole discretion whether any distribution meets
        the requirements of this provision;
      </p>
      <br />
      <p>
        b) must not misrepresent Information or deface or remove any trademarks
        transmitted with Information;
      </p>
      <br />
      <p>c) must not use Information for any illegal purpose;</p>
      <br />
      <p>
        d) must not use Information for Non-Display Use unless License is
        licensed to provide Managed Non-Display Use;
      </p>
      <br />
      <p>
        e) must not use the Information or any portion thereof in the creation,
        distribution, settlement or maintenance of any derivative work
        (including but not limited to financial products, indexes, quotes, spot
        prices, curves, surfaces, contracts for difference (CFDs) and other
        leveraged products, indicative optimized portfolio values (IOPV), net
        asset value (NAV), or analytical reference figures or values calculated
        from Information for purposes of fund administration and portfolio
        management services, risk management services or valuation services
        based on the Information) unless Subscriber is licensed to do so by CME;
      </p>
      <br />
      <p>
        f) must recognize all Intellectual Property Rights as acknowledged
        between Licensee and CME.
      </p>
      <br />
      <p>
        g) must maintain all records and provide all information required by
        each Licensee Group entity to meet Licensee’s record-keeping, reporting
        and payment obligations to CME;
      </p>
      <br />
      <p>
        h) all Subscriber Agreements must require Subscriber to maintain
        complete and accurate books and records, relating to all access to and
        use of, Information under the Subscriber Agreement for the most recent
        five (5) year period;
      </p>
      <br />
      <p>
        i) must allow CME or any auditors acting on behalf of CME to audit
        Subscriber records and use of Information;
      </p>
      <br />
      <p>
        j) shall obtain and provide any consent that may be required under all
        applicable laws, including the EU’s General Data Protection Regulation,
        as may be needed for CME or any auditors acting on behalf of CME to
        review and receive Personal Data, including but not limited to Personal
        Data pertaining to Subscribers or representatives of Licensee group,
        where requested by CME for purposes of verifying or ensuring compliance
        with Licensee’s obligations to CME or where requested by regulatory
        authority or pursuant to a valid court order, subpoena, or other legal
        instrument;
      </p>
      <br />
      <p>
        k) agrees that in addition to any other remedy, any Licensee Group
        entity may immediately suspend or terminate distribution of Information
        to Subscriber if Licensee or any Licensee Group entity has reason to
        suspect noncompliance with any of the Subscriber Agreement terms or if
        Licensee is required by CME to do so for any reason;
      </p>
      <br />
      <p>
        l) shall not distribute or permit distribution of Information to any
        entity located in any country subject to comprehensive sanctions by the
        Office of Foreign Assets Control and/or identified on the U.S.
        Department of the Treasury’s Specially Designated Nationals and Blocked
        Persons List;
      </p>
      <br />
      <p>
        m) acknowledges and agrees that to the maximum extent permitted by law,
        no CME Group entity nor any of their respective officer, directors,
        member, employees, agents, consultants or licensors shall have any
        liability to any Subscriber Group entity arising from use of
        Information;
      </p>
      <br />
      <p>
        and n) acknowledges and agrees that CME is a third-party beneficiary of
        the Subscriber Agreement, is entitled to the rights and benefits
        thereunder, and may enforce the provisions of the Subscriber Agreement
        directly against Subscriber as if it were a party thereto.
      </p>
      <br />
      <p>
        o) Acknowledge and agree that use of any Semi-Automated Trading System,
        by any Subscriber other than a Non-Professional Subscriber, requires a
        license directly between Subscriber and CME.
      </p>
    </div>
  </>
);

const BuildCopyICEFutures = ({ username, companyName }: AgreementProps) => (
  <>
    <Typography level="h3">ICE SUBSCRIBER AGREEMENT</Typography>
    <article className="page sans">
      <div className="page-body">
        <br />
        <p>
          This schedule is being entered on{" "}
          {`${new Date()
            .toISOString()
            .slice(0, 10)} between ${username} of ${companyName}`}
        </p>
        <br />
        <p>
          and ICE Data LLP (“ICE Data”) located at Milton Gate, 60 Chiswell
          Street, London, EC1Y 3SA, United Kingdom as supplied by ARTIS WORKS
          LTD.
        </p>
        <br />
        <p>
          The ICE Subscriber Agreement (“Agreement”) permits the Subscriber to
          receive and utilise ICE pricing data as per the terms below:
        </p>
        <br />
        <Box sx={{ marginLeft: "20px" }}>
          <ol className="list-numbers" style={{ marginLeft: "20px" }}>
            <li>
              <strong>DEFINITIONS.</strong>
              <br />
              <ol className="list-letters">
                <li>
                  "Derived Data" means i) Subscriber Permitted Uses Data, and
                  ii) data which has been created by the Subscriber based upon a
                  methodology applied to Pricing Data and approved in writing by
                  ICE.
                </li>
                <br />
                <li>
                  "Device" means any unit of equipment, fixed or portable, that
                  receives, accesses or displays Market Data in visible, audible
                  or other comprehensible form.
                </li>
                <br />
                <li>
                  "Exchanges" means ICE Futures Europe, ICE Futures Singapore,
                  ICE Futures U.S., ICE Abu Dhabi and ICE Endex.
                </li>
                <br />
                <li>
                  "Force Majeure Event" means any act, event, omission or
                  accident beyond the reasonable control of ICE Data including
                  but not limited to any natural disaster, war, terrorist
                  attack, act of god, fire, weather event, earthquake, labor
                  dispute or strike or power failure.
                </li>
                <br />
                <li>"Market Data" means Pricing Data and Derived Data.</li>
                <br />
                <li>
                  "Person" means any natural person, proprietorship,
                  corporation, partnership, limited liability company or other
                  organization.
                </li>
                <br />
                <li>
                  "Pricing Data" means real time data specifying the prices and
                  quantities at which the Traded Contracts have traded or are
                  available to trade, including Exchange-implied prices and
                  quantities.
                </li>
                <br />
                <li>
                  “Subscriber Permitted Uses Data" means charts and statistics
                  (e.g., moving averages, implied prices between expiries and
                  products, option Greeks) that are derived from the Pricing
                  Data by the Subscriber or Vendor, but Subscriber Permitted
                  Uses does not include use of the Pricing Data in connection
                  with the creation, compilation or preparation of an index,
                  basket or other similar financial product.
                </li>
                <br />
                <li>
                  “Traded Contracts" means contracts as traded on the Exchanges
                  listed above and any corresponding indices published by the
                  Exchanges.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>PROPRIETARY RIGHTS IN THE MARKET DATA.</strong>
              <br />
              <ol className="list-letters">
                <li>
                  Subscriber acknowledges and agrees that the Exchange has
                  exclusive and valuable property rights in the Market Data,
                  that such Market Data constitute valuable confidential
                  information and proprietary rights of each of the Exchanges,
                  not within the public domain, that such Market Data shall
                  remain valuable confidential information, and proprietary
                  rights of each of the Exchanges at least until the Exchanges
                  authorize placement of their respective Market Data in the
                  public domain, and that, but for this Agreement, Subscriber
                  would have no rights or access to such Market Data. Whether or
                  not a particular Exchange has placed its Market Data in the
                  public domain or has authorized the placement of its Market
                  Data in the public domain shall be determined according to the
                  terms of such Exchange’s agreement with Vendor, which
                  agreement is described in Section 3(a).
                </li>
                <br />
                <li>
                  Subscriber acknowledges and agrees that disclosure of any
                  Market Data, or any breach or threatened breach of any other
                  covenants or agreements contained herein, would cause
                  irreparable injury to each of the Exchanges for which money
                  damages would be an inadequate remedy. Accordingly, Subscriber
                  further acknowledges and agrees that each of the Exchanges
                  shall be entitled to specific performance and injunctive and
                  other equitable relief from the breach or threatened breach of
                  any provision, requirement or covenant of this Agreement
                  (including, without limitation, any disclosure or threatened
                  disclosure of Market Data) in addition to and not in
                  limitation of any other legal or equitable remedies which may
                  be available.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>RECEIPT OF MARKET DATA BY SUBSCRIBER.</strong>
              <br />
              <ol className="list-letters">
                <li>
                  The Agreement sets forth the terms and conditions upon which
                  Subscriber may receive and use the Exchanges Market Data.
                  Subscriber acknowledges that, notwithstanding such agreement,
                  each of the Exchanges may, in its discretion, discontinue
                  disseminating its own Market Data or change or eliminate its
                  own transmission method, speed or signal characteristics. In
                  addition, Subscriber acknowledges and agrees that the
                  Exchanges reserve the right to disapprove any Subscriber and
                  retain the right to direct Vendor to terminate any
                  Subscriber’s receipt of Market Data for any reason or no
                  reason, in which event the Exchanges shall so notify Vendor
                  and Vendor shall cease providing Market Data to Subscriber as
                  soon as practicable.
                </li>
                <br />
                <li>
                  (1) Except as provided in (2) below, Subscriber will use
                  Market Data only for its own internal business activities and
                  on the Devices designated by Subscriber in writing to Vendor
                  from time-to-time. (The term “for its own internal business
                  activities”, as used in the immediately preceding sentence
                  herein, means for Subscriber’s (a) trading, for its own
                  account or for the account of its customers, of commodity
                  futures contracts, options on commodity futures contracts or
                  similar derivative instruments, or (b) evaluating, for its own
                  internal business decisions or advice to its customers, the
                  movements or trends in markets for commodity futures
                  contracts, options on commodity future contracts, or like
                  derivative instruments, subject to all of the limitations set
                  forth below in this sub-paragraph as to the telephonic
                  disclosure to customers of a necessary and de minimis number
                  of segments of Market Data.) Subscriber agrees that it will
                  not communicate or otherwise furnish, or permit to be
                  communicated or otherwise furnished, the Market Data, in any
                  format, to any other party, nor allow any other party to take,
                  directly or indirectly, any of the Market Data from any office
                  or location as designated above, and will adopt and enforce
                  any policy that is reasonable to prevent the Market Data from
                  being taken therefrom. Subscriber specifically agrees, without
                  limiting or varying its obligations under paragraph 7 herein
                  or otherwise set forth in this Agreement, that Subscriber
                  shall not use or permit another person to use any Market Data
                  for the purposes of determining or arriving at any price,
                  including any settlement prices, for commodity futures
                  contracts, options on commodity futures contracts, or like
                  derivatives instruments traded on any exchange other than the
                  Exchanges. Subscriber will abide by any other limitations on
                  such use that the Exchanges may specify. Subscriber will use
                  its best efforts to ensure that its partners, officers,
                  directors, employees and agents maintain sole control and
                  physical possession of, and sole access to, Market Data
                  received through Devices in Subscriber's possession. (2)
                  Notwithstanding (1) above, Subscriber may, in the regular
                  course of its business, occasionally furnish, to each of its
                  customers, branch offices, and guaranteed introducing brokers,
                  in a quantity restricted to that necessary to enable
                  Subscriber to conduct its business, a de minimis number of
                  segments of Market Data. Such redissemination must be strictly
                  limited to telephonic communications not entailing the use of
                  computerized voice synthesization or any other technology and
                  must be strictly related to the trading activity of Subscriber
                  or any such recipients. Any such recipients must be advised by
                  Subscriber that such segments are proprietary and confidential
                  information not to be disclosed or disseminated to other
                  persons or entities. Subscriber agrees to make all reasonable
                  efforts to ensure that such recipients abide by the provisions
                  of this Agreement. Notwithstanding the foregoing, in the event
                  that a Subscriber is a newspaper which reports on, among other
                  things, exchanges on which commodity futures contracts or
                  options on commodity futures are traded, such Subscriber shall
                  be permitted to publish, in its newspaper published for the
                  day following the receipt by such Subscriber of the Market
                  Data, the Market Data received by Subscriber from Exchanges on
                  the day prior to such publication.
                </li>
                <br />
                <li>
                  In the event that Vendor has agreed to permit Subscriber to
                  receive, access or display Market Data through means other
                  than a Vendor-provided Device, such as an uncontrolled
                  datafeed, Subscriber will use its best efforts to ensure that
                  no other device, attachment or apparatus is used which may
                  allow third parties not subject to Subscriber's reporting
                  obligations under Section 3(b) above to access the Market
                  Data.
                </li>
              </ol>
            </li>
            <br />
            <li>
              <strong>REPORTING.</strong>
              <br />
              <p>
                Subscriber agrees to furnish promptly to Vendor any information
                or reports that may be required by the Exchanges as applicable
                and that is reasonably related to Subscriber’s receipt of Market
                Data. Subscriber further agrees to furnish promptly to Vendor
                any additional information or reports that may be required by
                the agreement between Vendor and Subscriber referred to in
                Section 3(a) as it relates to Subscriber’s receipt of Market
                Data.
              </p>
            </li>
            <br />
            <li>
              <strong>RIGHT OF INSPECTION AND AUDIT.</strong>
              <br />
              <p>
                During regular business hours, following a minimum of 10
                business days’ notice, any Persons designated by ICE Data on
                behalf of an Exchange may have access to Subscriber's offices or
                locations, under the Subscribers corporate security policies, in
                order to observe the use made of the Market Data and to examine
                and inspect any Devices, attachments or apparatuses, as well as
                any books and records required to be maintained by Subscriber
                under Sections 3(b) and 4 in connection with its receipt and use
                of Market Data. Subscriber will make prompt adjustment
                (including interest thereon at the rate of 1.5% per month),
                through Vendor, to compensate an Exchange that discovers an
                under- reported use of the Market Data by Subscriber. In
                addition, at the election of any such Exchange, Subscriber will
                be liable for the reasonable costs of any audit that reveals a
                discrepancy in such Exchange's favor of five percent (5%) or
                more of the amount of fees actually due any Exchange. Subscriber
                shall maintain the records and books upon which it bases the
                reporting for the Exchanges for six (6) years following the
                period to which the records and books relate. In the event that
                Subscriber fails to retain such records and books as required
                above, Subscriber agrees to pay each Exchange's reasonable
                estimate of any discrepancy discovered pursuant to any such
                audit.
              </p>
            </li>
            <br />
            <li>
              <strong>EXCHANGE FEES.</strong>
              <br />
              <p>
                Subscriber will pay Vendor (unless Vendor has assumed
                Subscriber’s payment obligations hereunder), for and on behalf
                of each of the Exchanges (as applicable), for the right to
                receive Market Data in accordance with the then-current fee
                schedule published by each of the Exchanges from time-to-time
                (including any and all applicable federal, state or local
                taxes). Each Exchange's fees are subject to modification by each
                of them at any time, without prior notice to Subscriber.
              </p>
            </li>
            <br />
            <li>
              <strong>
                COVENANTS, REPRESENTATIONS AND WARRANTIES OF SUBSCRIBER.
              </strong>
              <br />
              <p>
                Subscriber covenants, represents and warrants that it is not
                engaged in the business of distributing Market Data and that, to
                its knowledge after reasonable inquiry, it is receiving the
                Market Data from a Vendor that is authorized by the Exchanges to
                distribute the Market Data. Subscriber agrees that it will not
                use or permit any other Person to use Market Data for any
                illegal purpose. Subscriber agrees that it will not use Market
                Data in any way to compete with the Exchanges or Vendor, nor use
                the Market Data in any way so as to assist or allow a third
                party to compete with the Exchanges or Vendor. Subscriber agrees
                that the provision of Market Data by the Exchanges hereunder is
                conditioned upon Subscriber's strict compliance with the terms
                of this Agreement and that Vendor may, with or without notice
                and with or without cause, forthwith discontinue said service
                whenever in its judgment there has been any default or breach by
                Subscriber of the provisions hereof, or whenever directed to do
                so by any of the Exchanges.
              </p>
            </li>
            <br />
            <li>
              <strong>DISCLAIMER OF WARRANTIES.</strong>
              <br />
              <p>
                THE PRICING DATA IS PROVIDED “AS-IS”, “WHERE IS” AND MAKES NO
                WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE PRICING
                DATA. WITHOUT LIMITING ANY OTHER DISCLAIMERS, ICE DATA MAKES NO
                REPRESENTATION OR WARRANTY THAT THE PRICING DATA IS ACCURATE OR
                COMPLETE, THAT THE PRICING DATA WILL BE FREE FROM ERRORS OR
                DEFECTS OR THAT IT WILL BE UNINTERRUPTED, OR WITH RESPECT TO
                NON-INFRINGEMENT OR VALIDITY OF ANY INTELLECTUAL PROPERTY
                RIGHTS. EXCEPT TO THE EXTENT EXPRESSLY OTHERWISE STATED IN THIS
                AGREEMENT, ALL CONDITIONS, WARRANTIES, UNDERTAKINGS, TERMS AND
                REPRESENTATIONS OF ICE DATA AND ITS AFFILIATES, IMPLIED BY
                STATUTE, COMMON LAW OR OTHERWISE (INCLUDING, WITHOUT LIMITATION,
                WARRANTIES RELATING TO MERCHANTABILITY OR FITNESS FOR A
                PARTICULAR PURPOSE) IN RELATION TO THIS AGREEMENT ARE HEREBY
                EXCLUDED. EXCEPT IN THE CASE OF ICE DATA’S FRAUD OR WILLFUL
                MISCONDUCT, ICE DATA SHALL NOT BE LIABLE FOR ANY SPECIAL,
                INDIRECT, PUNITIVE OR CONSEQUENTIAL LOSS OR DAMAGE OF WHATSOEVER
                NATURE AND HOWSOEVER ARISING (WHETHER FOR NEGLIGENCE, BREACH OF
                CONTRACT, MISREPRESENTATION OR OTHERWISE), INCLUDING WITHOUT
                LIMITATION LOSS OF PROFITS, LOSS OF ANTICIPATED SAVINGS, LOSS OF
                BUSINESS OR LOSS OF GOODWILL.
              </p>
            </li>
            <br />
            <li>
              <strong>LIMITATIONS OF LIABILITY AND DAMAGES.</strong>
              <br />
              <p>
                Subscriber acknowledges and agrees that the Pricing Data is
                provided “AS-IS” and that neither ICE Data, the Exchanges or any
                of their Affiliates make any representation or warranty, express
                or implied, with respect to the Market Data. Without limiting
                any other disclaimers, neither ICE Data, The Exchanges or any of
                their Affiliates make any representation or warranty that the
                Pricing Data is accurate or complete, that the Pricing Data will
                be free from errors or will be uninterrupted.
              </p>
              <br />
              <p>
                To the fullest extent permitted by applicable law, under no
                circumstances, including gross negligence, willful misconduct,
                or intentional breach of this Agreement, none of ICE Data, any
                of its Affiliates or any of their respective directors,
                officers, managers, employees, agents or representatives shall
                be liable to the Subscriber or any third party for any damages,
                including, without limitation, incidental, indirect, special,
                consequential and punitive damages, arising from the use, misuse
                or inability to use the Market Data or any related
                documentation, know-how, inventions, discoveries, techniques,
                improvements or intellectual property rights or a breach of this
                Agreement by ICE Data or any of its Affiliates.
              </p>
            </li>
            <br />
            <li>
              <strong>TERM AND TERMINATION.</strong>
              <br />
              <p>
                Subject to Subscriber's strict compliance with the provisions of
                this Agreement, the provision of Market Data by any of the
                Exchanges hereunder will continue in force during the term of
                the agreement between Subscriber and Vendor and any renewal term
                thereof. In addition, it is understood that the provisions set
                forth in paragraphs 2(a) and 2(b) of this Agreement shall
                survive the termination of this Agreement.
              </p>
            </li>
            <br />
            <li>
              <strong>INDEMNIFICATION.</strong>
              <br />
              <p>
                Subscriber will indemnify, defend and hold ICE Data and the
                Exchanges, and their respective members, directors, officers,
                employees and agents harmless from and against any and all
                claims arising out of or in connection with this Agreement,
                including, without limitation, any liability, loss or damages
                (including, without limitation, attorneys’ fees and other
                expenses) caused by any inaccuracy in or omission from,
                Subscriber's failure to furnish or to keep, or Subscriber's
                delay in furnishing or keeping, any report or record required to
                be kept by Subscriber hereunder.
              </p>
            </li>
            <br />
            <li>
              <strong>MISCELLANEOUS.</strong>
              <br />
              <p>
                In case of any breach by Subscriber of its obligations
                hereunder, any action arising out of this Agreement between the
                Exchanges and Subscriber shall be governed and construed in
                accordance with the internal laws (and not the law of conflicts)
                of the State of New York. Subscriber may not assign all or any
                part of this Agreement without the prior written consent of the
                Exchanges (as applicable). Neither Vendor nor Subscriber may
                modify or amend the terms of this Agreement. In the event of any
                conflict between the terms and conditions of this Agreement and
                any other agreement relating to Subscriber's receipt and use of
                Market Data, including, without limitation, the agreement
                between Vendor and Subscriber referred to in Section 3(a), the
                terms and conditions of this Agreement will prevail. If, for any
                reason, one or more provisions of this Agreement is held
                invalid, the other provisions of the Agreement shall remain in
                full force and effect.
              </p>
            </li>
          </ol>
          <br />
          <p>
            <strong>
              BY CLICKING “ACCEPT” BELOW, YOU AGREE THAT THE FOLLOWING IS TRUE:
              (1) YOU REPRESENT THAT YOU HAVE ACTUAL AUTHORITY TO ENTER INTO
              THIS AGREEMENT ON BEHALF OF SUBSCRIBER; (2) THAT YOU HAVE READ THE
              TERMS STATED ABOVE; (3) YOU UNDERSTAND THE TERMS STATED ABOVE; (4)
              A PRINTOUT OF THE TERMS STATED ABOVE WILL CONSTITUTE A "WRITING"
              UNDER ANY APPLICABLE LAW OR REGULATION; AND (5) YOU AGREE TO ABIDE
              BY ALL THE TERMS OF THE AGREEMENT STATED ABOVE.
            </strong>
          </p>
        </Box>
      </div>
    </article>
  </>
);

const BuildCopyICEEndex = ({ username, companyName }: AgreementProps) => (
  <>
    <Typography level="h3">ICE ENDEX SUBSCRIBER AGREEMENT</Typography>
    <article className="page sans">
      <div className="page-body">
        <br />
        <p>
          This schedule is being entered on{" "}
          {`${new Date()
            .toISOString()
            .slice(0, 10)} between ${username} of ${companyName}`}
        </p>
        <br />
        <p>
          and ICE Data LLP (“ICE Data”) located at Milton Gate, 60 Chiswell
          Street, London, EC1Y 3SA, United Kingdom as supplied by ARTIS WORKS
          LTD.
        </p>
        <br />
        <p>
          The ICE ENDEX Subscriber Agreement (“Agreement”) permits the
          Subscriber to receive and utilise ICE pricing data as per the terms
          below:
        </p>
        <br />
        <ol className="list-numbers" style={{ marginLeft: "20px" }}>
          <li>
            <Typography level="title-md">DEFINITIONS.</Typography>
            <br />
            <ol className="list-letters">
              <li>
                "Derived Data" means i) Subscriber Permitted Uses Data, and ii)
                data which has been created by the Subscriber based upon a
                methodology applied to Pricing Data and approved in writing by
                ICE.
              </li>
              <br />
              <li>
                "Device" means any unit of equipment, fixed or portable, that
                receives, accesses or displays Market Data in visible, audible
                or other comprehensible form.
              </li>
              <br />
              <li>
                "Exchanges" means ICE Futures Europe, ICE Futures Singapore, ICE
                Futures U.S., ICE Abu Dhabi and ICE Endex.
              </li>
              <br />
              <li>
                "Force Majeure Event" means any act, event, omission or accident
                beyond the reasonable control of ICE Data including but not
                limited to any natural disaster, war, terrorist attack, act of
                god, fire, weather event, earthquake, labor dispute or strike or
                power failure.
              </li>
              <br />
              <li>"Market Data" means Pricing Data and Derived Data.</li>
              <br />
              <li>
                "Person" means any natural person, proprietorship, corporation,
                partnership, limited liability company or other organization.
              </li>
              <br />
              <li>
                "Pricing Data" means real time data specifying the prices and
                quantities at which the Traded Contracts have traded or are
                available to trade, including Exchange-implied prices and
                quantities.
              </li>
              <br />
              <li>
                “Subscriber Permitted Uses Data" means charts and statistics
                (e.g., moving averages, implied prices between expiries and
                products, option Greeks) that are derived from the Pricing Data
                by the Subscriber or Vendor, but Subscriber Permitted Uses does
                not include use of the Pricing Data in connection with the
                creation, compilation or preparation of an index, basket or
                other similar financial product.
              </li>
              <br />
              <li>
                “Traded Contracts" means contracts as traded on the Exchanges
                listed above and any corresponding indices published by the
                Exchanges.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <Typography level="title-md">
              {" "}
              PROPRIETARY RIGHTS IN THE MARKET DATA.
            </Typography>
            <br />
            <ol className="list-letters">
              <li>
                Subscriber acknowledges and agrees that the Exchange has
                exclusive and valuable property rights in the Market Data, that
                such Market Data constitute valuable confidential information
                and proprietary rights of each of the Exchanges, not within the
                public domain, that such Market Data shall remain valuable
                confidential information, and proprietary rights of each of the
                Exchanges at least until the Exchanges authorize placement of
                their respective Market Data in the public domain, and that, but
                for this Agreement, Subscriber would have no rights or access to
                such Market Data. Whether or not a particular Exchange has
                placed its Market Data in the public domain or has authorized
                the placement of its Market Data in the public domain shall be
                determined according to the terms of such Exchange’s agreement
                with Vendor, which agreement is described in Section 3(a).
              </li>
              <br />
              <li>
                Subscriber acknowledges and agrees that disclosure of any Market
                Data, or any breach or threatened breach of any other covenants
                or agreements contained herein, would cause irreparable injury
                to each of the Exchanges for which money damages would be an
                inadequate remedy. Accordingly, Subscriber further acknowledges
                and agrees that each of the Exchanges shall be entitled to
                specific performance and injunctive and other equitable relief
                from the breach or threatened breach of any provision,
                requirement or covenant of this Agreement (including, without
                limitation, any disclosure or threatened disclosure of Market
                Data) in addition to and not in limitation of any other legal or
                equitable remedies which may be available.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <Typography level="title-md">
              RECEIPT OF MARKET DATA BY SUBSCRIBER.
            </Typography>
            <br />
            <ol className="list-letters">
              <li>
                The Agreement sets forth the terms and conditions upon which
                Subscriber may receive and use the Exchanges Market Data.
                Subscriber acknowledges that, notwithstanding such agreement,
                each of the Exchanges may, in its discretion, discontinue
                disseminating its own Market Data or change or eliminate its own
                transmission method, speed or signal characteristics. In
                addition, Subscriber acknowledges and agrees that the Exchanges
                reserve the right to disapprove any Subscriber and retain the
                right to direct Vendor to terminate any Subscriber’s receipt of
                Market Data for any reason or no reason, in which event the
                Exchanges shall so notify Vendor and Vendor shall cease
                providing Market Data to Subscriber as soon as practicable.
              </li>
              <br />
              <li>
                (1) Except as provided in (2) below, Subscriber will use Market
                Data only for its own internal business activities and on the
                Devices designated by Subscriber in writing to Vendor from
                time-to-time. (The term “for its own internal business
                activities”, as used in the immediately preceding sentence
                herein, means for Subscriber’s (a) trading, for its own account
                or for the account of its customers, of commodity futures
                contracts, options on commodity futures contracts or similar
                derivative instruments, or (b) evaluating, for its own internal
                business decisions or advice to its customers, the movements or
                trends in markets for commodity futures contracts, options on
                commodity future contracts, or like derivative instruments,
                subject to all of the limitations set forth below in this
                sub-paragraph as to the telephonic disclosure to customers of a
                necessary and de minimis number of segments of Market Data.)
                Subscriber agrees that it will not communicate or otherwise
                furnish, or permit to be communicated or otherwise furnished,
                the Market Data, in any format, to any other party, nor allow
                any other party to take, directly or indirectly, any of the
                Market Data from any office or location as designated above, and
                will adopt and enforce any policy that is reasonable to prevent
                the Market Data from being taken therefrom. Subscriber
                specifically agrees, without limiting or varying its obligations
                under paragraph 7 herein or otherwise set forth in this
                Agreement, that Subscriber shall not use or permit another
                person to use any Market Data for the purposes of determining or
                arriving at any price, including any settlement prices, for
                commodity futures contracts, options on commodity futures
                contracts, or like derivatives instruments traded on any
                exchange other than the Exchanges. Subscriber will abide by any
                other limitations on such use that the Exchanges may specify.
                Subscriber will use its best efforts to ensure that its
                partners, officers, directors, employees and agents maintain
                sole control and physical possession of, and sole access to,
                Market Data received through Devices in Subscriber's possession.
                (2) Notwithstanding (1) above, Subscriber may, in the regular
                course of its business, occasionally furnish, to each of its
                customers, branch offices, and guaranteed introducing brokers,
                in a quantity restricted to that necessary to enable Subscriber
                to conduct its business, a de minimis number of segments of
                Market Data. Such redissemination must be strictly limited to
                telephonic communications not entailing the use of computerized
                voice synthesization or any other technology and must be
                strictly related to the trading activity of Subscriber or any
                such recipients. Any such recipients must be advised by
                Subscriber that such segments are proprietary and confidential
                information not to be disclosed or disseminated to other persons
                or entities. Subscriber agrees to make all reasonable efforts to
                ensure that such recipients abide by the provisions of this
                Agreement. Notwithstanding the foregoing, in the event that a
                Subscriber is a newspaper which reports on, among other things,
                exchanges on which commodity futures contracts or options on
                commodity futures are traded, such Subscriber shall be permitted
                to publish, in its newspaper published for the day following the
                receipt by such Subscriber of the Market Data, the Market Data
                received by Subscriber from Exchanges on the day prior to such
                publication.
              </li>
              <br />
              <li>
                In the event that Vendor has agreed to permit Subscriber to
                receive, access or display Market Data through means other than
                a Vendor-provided Device, such as an uncontrolled datafeed,
                Subscriber will use its best efforts to ensure that no other
                device, attachment or apparatus is used which may allow third
                parties not subject to Subscriber's reporting obligations under
                Section 3(b) above to access the Market Data.
              </li>
            </ol>
          </li>
          <br />
          <li>
            <Typography level="title-md">REPORTING.</Typography>
            <br />
            <p>
              Subscriber agrees to furnish promptly to Vendor any information or
              reports that may be required by the Exchanges as applicable and
              that is reasonably related to Subscriber’s receipt of Market Data.
              Subscriber further agrees to furnish promptly to Vendor any
              additional information or reports that may be required by the
              agreement between Vendor and Subscriber referred to in Section
              3(a) as it relates to Subscriber’s receipt of Market Data.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">
              RIGHT OF INSPECTION AND AUDIT.
            </Typography>
            <br />
            <p>
              During regular business hours, following a minimum of 10 business
              days’ notice, any Persons designated by ICE Data on behalf of an
              Exchange may have access to Subscriber's offices or locations,
              under the Subscribers corporate security policies, in order to
              observe the use made of the Market Data and to examine and inspect
              any Devices, attachments or apparatuses, as well as any books and
              records required to be maintained by Subscriber under Sections
              3(b) and 4 in connection with its receipt and use of Market Data.
              Subscriber will make prompt adjustment (including interest thereon
              at the rate of 1.5% per month), through Vendor, to compensate an
              Exchange that discovers an under- reported use of the Market Data
              by Subscriber. In addition, at the election of any such Exchange,
              Subscriber will be liable for the reasonable costs of any audit
              that reveals a discrepancy in such Exchange's favor of five
              percent (5%) or more of the amount of fees actually due any
              Exchange. Subscriber shall maintain the records and books upon
              which it bases the reporting for the Exchanges for six (6) years
              following the period to which the records and books relate. In the
              event that Subscriber fails to retain such records and books as
              required above, Subscriber agrees to pay each Exchange's
              reasonable estimate of any discrepancy discovered pursuant to any
              such audit.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">EXCHANGE FEES.</Typography>
            <br />
            <p>
              Subscriber will pay Vendor (unless Vendor has assumed Subscriber’s
              payment obligations hereunder), for and on behalf of each of the
              Exchanges (as applicable), for the right to receive Market Data in
              accordance with the then-current fee schedule published by each of
              the Exchanges from time-to-time (including any and all applicable
              federal, state or local taxes). Each Exchange's fees are subject
              to modification by each of them at any time, without prior notice
              to Subscriber.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">
              COVENANTS, REPRESENTATIONS AND WARRANTIES OF SUBSCRIBER.
            </Typography>
            <br />
            <p>
              Subscriber covenants, represents and warrants that it is not
              engaged in the business of distributing Market Data and that, to
              its knowledge after reasonable inquiry, it is receiving the Market
              Data from a Vendor that is authorized by the Exchanges to
              distribute the Market Data. Subscriber agrees that it will not use
              or permit any other Person to use Market Data for any illegal
              purpose. Subscriber agrees that it will not use Market Data in any
              way to compete with the Exchanges or Vendor, nor use the Market
              Data in any way so as to assist or allow a third party to compete
              with the Exchanges or Vendor. Subscriber agrees that the provision
              of Market Data by the Exchanges hereunder is conditioned upon
              Subscriber's strict compliance with the terms of this Agreement
              and that Vendor may, with or without notice and with or without
              cause, forthwith discontinue said service whenever in its judgment
              there has been any default or breach by Subscriber of the
              provisions hereof, or whenever directed to do so by any of the
              Exchanges.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">DISCLAIMER OF WARRANTIES.</Typography>
            <br />
            <p>
              THE PRICING DATA IS PROVIDED “AS-IS”, “WHERE IS” AND MAKES NO
              WARRANTIES, EXPRESS OR IMPLIED, WITH RESPECT TO THE PRICING DATA.
              WITHOUT LIMITING ANY OTHER DISCLAIMERS, ICE DATA MAKES NO
              REPRESENTATION OR WARRANTY THAT THE PRICING DATA IS ACCURATE OR
              COMPLETE, THAT THE PRICING DATA WILL BE FREE FROM ERRORS OR
              DEFECTS OR THAT IT WILL BE UNINTERRUPTED, OR WITH RESPECT TO
              NON-INFRINGEMENT OR VALIDITY OF ANY INTELLECTUAL PROPERTY RIGHTS.
              EXCEPT TO THE EXTENT EXPRESSLY OTHERWISE STATED IN THIS AGREEMENT,
              ALL CONDITIONS, WARRANTIES, UNDERTAKINGS, TERMS AND
              REPRESENTATIONS OF ICE DATA AND ITS AFFILIATES, IMPLIED BY
              STATUTE, COMMON LAW OR OTHERWISE (INCLUDING, WITHOUT LIMITATION,
              WARRANTIES RELATING TO MERCHANTABILITY OR FITNESS FOR A PARTICULAR
              PURPOSE) IN RELATION TO THIS AGREEMENT ARE HEREBY EXCLUDED. EXCEPT
              IN THE CASE OF ICE DATA’S FRAUD OR WILLFUL MISCONDUCT, ICE DATA
              SHALL NOT BE LIABLE FOR ANY SPECIAL, INDIRECT, PUNITIVE OR
              CONSEQUENTIAL LOSS OR DAMAGE OF WHATSOEVER NATURE AND HOWSOEVER
              ARISING (WHETHER FOR NEGLIGENCE, BREACH OF CONTRACT,
              MISREPRESENTATION OR OTHERWISE), INCLUDING WITHOUT LIMITATION LOSS
              OF PROFITS, LOSS OF ANTICIPATED SAVINGS, LOSS OF BUSINESS OR LOSS
              OF GOODWILL.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">
              LIMITATIONS OF LIABILITY AND DAMAGES.
            </Typography>
            <br />
            <p>
              Subscriber acknowledges and agrees that the Pricing Data is
              provided “AS-IS” and that neither ICE Data, the Exchanges or any
              of their Affiliates make any representation or warranty, express
              or implied, with respect to the Market Data. Without limiting any
              other disclaimers, neither ICE Data, The Exchanges or any of their
              Affiliates make any representation or warranty that the Pricing
              Data is accurate or complete, that the Pricing Data will be free
              from errors or will be uninterrupted.
            </p>
            <br />
            <p>
              To the fullest extent permitted by applicable law, under no
              circumstances, including gross negligence, willful misconduct, or
              intentional breach of this Agreement, none of ICE Data, any of its
              Affiliates or any of their respective directors, officers,
              managers, employees, agents or representatives shall be liable to
              the Subscriber or any third party for any damages, including,
              without limitation, incidental, indirect, special, consequential
              and punitive damages, arising from the use, misuse or inability to
              use the Market Data or any related documentation, know-how,
              inventions, discoveries, techniques, improvements or intellectual
              property rights or a breach of this Agreement by ICE Data or any
              of its Affiliates.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">TERM AND TERMINATION.</Typography>
            <br />
            <p>
              Subject to Subscriber's strict compliance with the provisions of
              this Agreement, the provision of Market Data by any of the
              Exchanges hereunder will continue in force during the term of the
              agreement between Subscriber and Vendor and any renewal term
              thereof. In addition, it is understood that the provisions set
              forth in paragraphs 2(a) and 2(b) of this Agreement shall survive
              the termination of this Agreement.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">INDEMNIFICATION.</Typography>
            <br />
            <p>
              Subscriber will indemnify, defend and hold ICE Data and the
              Exchanges, and their respective members, directors, officers,
              employees and agents harmless from and against any and all claims
              arising out of or in connection with this Agreement, including,
              without limitation, any liability, loss or damages (including,
              without limitation, attorneys’ fees and other expenses) caused by
              any inaccuracy in or omission from, Subscriber's failure to
              furnish or to keep, or Subscriber's delay in furnishing or
              keeping, any report or record required to be kept by Subscriber
              hereunder.
            </p>
          </li>
          <br />
          <li>
            <Typography level="title-md">MISCELLANEOUS.</Typography>
            <br />
            <p>
              In case of any breach by Subscriber of its obligations hereunder,
              any action arising out of this Agreement between the Exchanges and
              Subscriber shall be governed and construed in accordance with the
              internal laws (and not the law of conflicts) of the State of New
              York. Subscriber may not assign all or any part of this Agreement
              without the prior written consent of the Exchanges (as
              applicable). Neither Vendor nor Subscriber may modify or amend the
              terms of this Agreement. In the event of any conflict between the
              terms and conditions of this Agreement and any other agreement
              relating to Subscriber's receipt and use of Market Data,
              including, without limitation, the agreement between Vendor and
              Subscriber referred to in Section 3(a), the terms and conditions
              of this Agreement will prevail. If, for any reason, one or more
              provisions of this Agreement is held invalid, the other provisions
              of the Agreement shall remain in full force and effect.
            </p>
          </li>
        </ol>
        <br />
        <p>
          <strong>
            BY CLICKING “ACCEPT” BELOW, YOU AGREE THAT THE FOLLOWING IS TRUE:
            (1) YOU REPRESENT THAT YOU HAVE ACTUAL AUTHORITY TO ENTER INTO THIS
            AGREEMENT ON BEHALF OF SUBSCRIBER; (2) THAT YOU HAVE READ THE TERMS
            STATED ABOVE; (3) YOU UNDERSTAND THE TERMS STATED ABOVE; (4) A
            PRINTOUT OF THE TERMS STATED ABOVE WILL CONSTITUTE A "WRITING" UNDER
            ANY APPLICABLE LAW OR REGULATION; AND (5) YOU AGREE TO ABIDE BY ALL
            THE TERMS OF THE AGREEMENT STATED ABOVE.
          </strong>
        </p>
      </div>
    </article>
  </>
);

const BuildCopyIDS = ({ username, companyName }: AgreementProps) => (
  <>
    <Typography level="h3">IDS SUBSCRIBER AGREEMENT</Typography>
    <article className="page sans">
      <div className="page-body">
        <br />
        <p>
          This schedule is being entered on{" "}
          {`${new Date()
            .toISOString()
            .slice(0, 10)} between ${username} of ${companyName}`}
        </p>
        <br />
        <p>and IDS as supplied by ARTIS WORKS LTD.</p>
        <br />
        <p>
          The IDS Subscriber Agreement (“Agreement”) permits the Subscriber to
          receive and utilise FX pricing data as per the terms below:
        </p>
        <br />
        <p>
          <strong>
            BY CLICKING “ACCEPT” BELOW, YOU AGREE THAT THE FOLLOWING IS TRUE:
            (1) YOU REPRESENT THAT YOU HAVE ACTUAL AUTHORITY TO ENTER INTO THIS
            AGREEMENT ON BEHALF OF SUBSCRIBER; (2) THAT YOU HAVE READ THE TERMS
            STATED ABOVE; (3) YOU UNDERSTAND THE TERMS STATED ABOVE; (4) A
            PRINTOUT OF THE TERMS STATED ABOVE WILL CONSTITUTE A "WRITING" UNDER
            ANY APPLICABLE LAW OR REGULATION; AND (5) YOU AGREE TO ABIDE BY ALL
            THE TERMS OF THE AGREEMENT STATED ABOVE.
          </strong>
        </p>
      </div>
    </article>
  </>
);

const BuildCopyTPIcapXC = ({ username }: AgreementProps) => (
  <>
    <Typography level="h3">TP ICAP SUBSCRIBER AGREEMENT</Typography>
    <article className="page sans">
      <div className="page-body">
        <br />
        <p>
          This Terms of Use agreement (the “Terms”) is being entered into on{" "}
          {`${new Date().toISOString().slice(0, 10)} between ${username}`}
          and TP ICAP E&C Limited (“TP ICAP”), a company registered in England
          under company number 00966604 and with its registered office at 135
          Bishopsgate, London, EC2M 3TP.
        </p>
        <br />
        <p>
          <strong>Information</strong> means all TP ICAP information, data and
          other content provided to you by Artis Works Limited (“
          <strong>Artis</strong>”).
        </p>
        <br />
        <p>
          You are licenced to use the Information for your internal business use
          only in support of your internal front office trading activities or
          the provision of liquidity by your internal front office, in each
          case, carried out using, or provided with respect to, the trading
          services of TP ICAP unless specifically authorised otherwise in
          writing by TP ICAP or any of its affiliates or its or their respective
          authorized partners (“
          <strong>Permitted Purpose</strong>”). The licence of, and the use of,
          the Information for the Permitted Purpose shall be governed by the
          terms and conditions of with the{" "}
          <a
            href="https://tpicap.com/tpicap/sites/g/files/escbpb106/files/2023-10/MLA%20November%202021.pdf"
            target="_blank"
            rel="noreferrer"
          >
            Master Data Licence (“MLA”)
          </a>
          , which is incorporated into these Terms by reference hereto.
        </p>
        <br />
        <ol className="list-numbers licence">
          <li>
            You acknowledge and agree that TP ICAP has exclusive and valuable
            property rights in the Information, that such Information
            constitutes valuable confidential information and proprietary rights
            of TP ICAP.
          </li>
          <br />
          <li>
            No Information nor anything arising from your use of it (i) as
            investment, tax, accounting or legal advice, (ii) as an offer,
            recommendation or solicitation of an offer to sell or buy any
            security or any other financial instrument or to participate in any
            trading strategy, or (iii) as an endorsement, recommendation or
            sponsorship of any security or other financial instrument. In the
            UK, Information accessible via a platform is intended for use only
            by persons falling within Articles 19(5) and 49(2)(a) to (d) of the
            Financial Services and Markets Act 2000 (Financial Promotion) Order
            2005 (as amended), who have professional experience in matters
            relating to investments, or to persons to whom it can be otherwise
            lawfully distributed. The Information is provided “as is“ and
            “as-available” without any representations or warranties of any kind
            (whether express or implied). To the fullest extent permissible
            under applicable law, TP ICAP hereby disclaims all representations
            and warranties, express, implied or statutory, including, without
            limitation, all implied warranties of title, non-infringement,
            merchantability, and fitness for a particular purpose, and all
            representations and warranties (i) arising from course of dealing,
            course of performance or custom or trade usage, (ii) relating to the
            adequacy, timeliness, accuracy or completeness of any Information
            including but not limited to the appropriateness of the information,
            methodology and/or any derived price (iii) that the provision of
            Information will be uninterrupted, error-free, or secure, (iv) that
            defects will be corrected, (v) any loss of profit, business or
            goodwill or any indirect or consequential loss or special or
            exemplary damage arising in connection with your use of the
            Information under any theory of liability, regardless of whether TP
            ICAP has been notified of the possibility of such damage or loss,
            and (vii) that the Information will operate in conjunction, or be
            compatible with any hardware, system, equipment or otherwise. You
            assume total responsibility and risk for your use of, or reliance
            on, the Information provided by Artis. Any Information contained on
            or via Artis is subject to change at any time without notice.
          </li>
          <br />
          <li>
            You agree to indemnify, defend and hold harmless TP ICAP from and
            against any claim, action or demand, including, without limitation,
            reasonable legal fees, made by any third party due to or arising out
            of your breach of these Terms and/or your use of the Information.
            The fact that TP ICAP has made the Information, available to you via
            Artis constitutes neither a recommendation that you enter into a
            particular transaction nor a representation that any product
            described on Artis’ platform is suitable or appropriate for you.{" "}
          </li>
          <br />
          <li>
            To the fullest extent permitted by applicable law, TP ICAP shall not
            be responsible or liable for any direct, indirect, incidental,
            consequential, special, exemplary, punitive or other losses or
            damages (including, but not limited to, damages for loss of profits,
            loss of business, use, data or other intangible damages, even if
            such party has been advised of the possibility of such damages),
            under any contract, tort (including, without limitation, negligence
            and strict liability) or other legal theory, howsoever caused,
            arising out of or relating in any way to the Information or any
            errors or omissions therein, or your use of, or inability to use, or
            reliance on the Information. Your sole remedy for dissatisfaction
            with the Information is to stop using the Information.
          </li>
          <br />
          <li>
            Upon thirty (30) days’ written notice, at your own expense, at all
            reasonable times you shall permit TP ICAP to have access to and/or
            provide to TP ICAP accounts, records and other information
            reasonably required by TP ICAP (whether controlled by you or any of
            your affiliates or your, its or their subcontractors) relating to,
            your access and/or use of the Information in order to verify your
            compliance with these Terms. Where an audit identifies that the
            Information has been used for purposes other than the Permitted
            Purpose then TP ICAP or one of its affiliates shall be entitled to
            recover fees due for such unlicenced use of the Information.
          </li>
          <br />
          <li>
            TP ICAP reserves the right to suspend access to the Information for
            any legal or regulatory or during the investigation of a suspected
            breach of these Terms by you.
          </li>
          <br />
          <li>
            These Terms will continue in force for as long as you are contracted
            to both TP ICAP and Artis for the receipt of the Information via
            Artis and will terminate immediately upon cessation of your relevant
            contract with either TP ICAP or Artis
          </li>
          <br />
          <li>
            These Terms shall be governed by and construed in accordance with
            the laws of England and Wales. Should you have any queries or
            comments regarding these Terms, please contact TP ICAP at: General
            Counsel, EMEA, 135 Bishopsgate, London, EC2M 3TP or{" "}
            <a href="mailto:EMEAlegal@tpicap.com">EMEAlegal@tpicap.com</a>.
          </li>
        </ol>
        <br />
        <p>
          <strong>
            BY CLICKING “ACCEPT” BELOW, YOU CONFIRM THE FOLLOWING: (1) YOU
            REPRESENT THAT YOU HAVE AUTHORITY TO ENTER INTO THESE TERMS ON
            BEHALF OF SUBSCRIBER; (2) THAT YOU HAVE READ THE TERMS STATED ABOVE;
            (3) YOU UNDERSTAND THE TERMS STATED ABOVE; AND (4) YOU AGREE TO
            ADHERE TO THE TERMS STATED ABOVE.
          </strong>
        </p>
      </div>
    </article>
  </>
);

const BuildCopyArtisXC = ({ username }: AgreementProps) => (
  <>
    <Typography level="h3">
      Artis Folio SaaS Services - End User Licence Agreement
    </Typography>
    <article className="licence">
      <div className="page-body">
        <br />
        <p>
          These end user licence terms and conditions ("Licence Terms") were
          updated on 30th January 2024.
        </p>
        <br />
        <p>Please read these Licence Terms carefully.</p>
        <br />
        <p>
          In being granted permission by Artis Works Ltd to access and use, in
          whole or in part, whether on a trial basis or as a full commercial
          subscription, Artis Folio Software-as-a-Service (SaaS) located at
          folio.artis.works or such other website or platform (accessible via
          your User Account or otherwise), including the Services, and
          Documentation (collectively "Artis Folio"), you agree to be bound by
          these Licence Terms. If you disagree with these Licence Terms, do not
          access or use any part of Artis Folio.
        </p>
        <br />
        <p>
          We may modify these Licence Terms at any time, and your continued use
          of Artis Folio after we post modifications to these Licence Terms
          means that you agree to be bound by them, as modified.
        </p>
        <br />
        <Typography level="h3">
          Who we are and the purpose of these Licence Terms
        </Typography>
        <br />
        <p>
          We are Artis Works Ltd, including but not limited to connected group
          companies and affiliates, and www.artis.works and all of its
          associated websites and Artis Folio is operated by us ("we", "us",
          "our"). We are registered in England and Wales under company number
          12603784 and have our registered office at Fifth Floor, One New
          Change, London EC4M 9AF.
        </p>
        <br />
        <p>
          In consideration of the licence fees paid in connection with your use
          of Artis Folio or in respect of a trial subscription, for the purpose
          of evaluating the use and application of Artis Folio and subject to
          these Licence Terms, we grant you a worldwide, non-exclusive,
          non-transferable, revocable, non-sublicensable licence to:
        </p>
        <br />
        <ul>
          <li>
            Access and use Artis Folio, which includes any online or electronic
            documentation related to Artis Folio provided by us to you in
            downloading and/or accessing Artis Folio via folio.artis.works or
            such other website or platform (the "Documentation"); and
          </li>
          <br />
          <li>
            The services, data, content or otherwise provided by us or on our
            behalf in connection with these Licence Terms, including the User
            Account provided to facilitate your access to any such services (the
            "Services");
          </li>
        </ul>
        <br />
        <p>
          for the purpose of using Artis Folio on behalf of your Authorised
          Entity and its internal business purposes.
        </p>
        <br />
        <p>
          We reserve the right to suspend, restrict, withdraw, access to, or
          availability of, part or all of Artis Folio we provide to any and all
          users (whether with User Accounts or not) without notice.
        </p>
        <br />
        <p>
          In addition to the other obligations in these Licence Terms, your use
          of Artis Folio is subject to all of the following:
        </p>
        <br />
        <ul>
          <li>
            You obtaining the relevant authorisation from the third-party
            contracting entity, whether a corporation, partnership, limited
            liability company, organisation or otherwise, who grants you, on its
            behalf, as one of its authorised users, access to and use of Artis
            Folio (the “Authorised Entity”) and with whom we have one or more
            contracts in place to access and use Artis Folio (the “Authorised
            Entity Agreements”);
          </li>
          <br />
          <li>
            Your compliance with all the obligations on you as an authorised
            user on behalf of the Authorised Entity under the Authorised Entity
            Agreements; and
          </li>
          <br />
          <li>
            Your compliance with any and all obligations under which we are
            bound to comply with any third-party data exchange (and which, in
            turn, we require you to comply with) that supplies us with the data
            to allow us to provide the Services via Artis Folio (the “Exchange
            Agreements”).
          </li>
        </ul>
        <br />
        <p>
          You represent and warrant to us under these Licence Terms that you
          have the express written permission from your Authorised Entity to use
          Artis Folio. Without such express permission, you will not access,
          use, or have any other rights regarding Artis Folio. You agree to
          notify us immediately should your express permission cease for any
          reason.
        </p>
        <br />
        <p>
          These Licence Terms apply to all users of Artis Folio in any
          jurisdiction worldwide, and these Licence Terms both supplement and
          incorporate, by reference, the obligations on you as an authorised
          user under the Authorised Entity Agreements and any obligations that
          you are required to comply with for us to meet our obligations under
          the Exchange Agreements.
        </p>
        <br />
        <Typography level="h3">How to contact us</Typography>
        <br />
        <p>You can contact us to receive Support using:</p>
        <br />
        <ul>
          <li>Our registered address stated above;</li>
          <br />
          <li>The web chat functionality directly in Artis Folio;</li>
          <br />
          <li>The e-mail address support@artis.works; or</li>
          <br />
          <li>WhatsApp on the number +44 7537 143416.</li>
        </ul>
        <br />
        <Typography level="h3">How we will contact you</Typography>
        <br />
        <p>
          We will send you any agreements, statements and other notices using
          email, WhatsApp, or phone. If any of your contact details change,
          including if you move abroad, you will let us know your new contact
          details as soon as possible.
        </p>
        <br />
        <Typography level="h3">Policies</Typography>
        <br />
        <p>
          Our privacy policy and cookies policy are located at
          https://www.artis.works and set out the terms on which we process any
          personal data we collect from you or that you provide to us (our
          “Policies”).
        </p>
        <br />
        <p>
          By using Artis Folio, you consent to such processing and warrant that
          all data you provide to us is accurate and up to date.
        </p>
        <br />
        <p>
          It is your responsibility to check our Policies from time to time, as
          any and all changes made are binding on your access to and use of
          Artis Folio.
        </p>
        <br />
        <Typography level="h3">Complaints</Typography>
        <br />
        <p>
          We strive to provide excellent customer service at all times. We go
          that extra mile for our customers and authorised users. If you are not
          satisfied with Artis Folio at any time, please reach out to us
          directly.
        </p>
        <br />
        <Typography level="h3">Access and use of Artis Folio </Typography>
        <br />
        <p>
          We are the owner or the licensee of all intellectual property rights
          in Artis Folio. Those works are protected by laws and treaties around
          the world. Other than as set out in these Licence Terms, all other
          such rights are reserved and no rights or licenses are granted to you
          in such works.
        </p>
        <br />
        <p>
          You must not modify copies of any results from your use of Artis
          Folio, whether digital or physical copies.
        </p>
        <br />
        <Typography level="h3">Maintenance</Typography>
        <br />
        <p>
          We shall use commercially reasonable endeavours to make the Services
          available twenty four (24) hours a day, seven (7) days a week, except
          for:
        </p>
        <br />
        <ul>
          <li>
            Planned maintenance of Artis Folio carried out during the
            maintenance windows of 11:00pm to 01:00am weekdays and as necessary
            weekends UK time ("Planned Maintenance"); and
          </li>
          <br />
          <li>
            Unscheduled maintenance performed outside of 9:00am to 5:30pm UK
            time weekdays, as provided for under your Authorised Entity
            Agreement.
          </li>
        </ul>
        <br />
        <Typography level="h3">Support</Typography>
        <br />
        <p>
          We will, five days a week, 24 hours a day including UK Bank Holidays,
          excluding Christmas Day and Planned Maintenance, provide you with:
        </p>
        <br />
        <ul>
          <li>
            Access to support provided by help desk agents sufficiently
            qualified and experienced to identify and resolve most support
            issues relating to Artis Folio (e.g. any questions about the
            functionality of Artis Folio);
          </li>
          <br />
          <li>
            Access to service requests for our consultants to carry out the
            functionality of Artis Folio on your behalf (e.g. product creation,
            user management, etc); and{" "}
          </li>
          <br />
          <li>
            Maintenance of the then-current version or release of Artis Folio.
          </li>
        </ul>
        <br />
        <Typography level="h3">Licence use restrictions</Typography>
        <br />
        <p>You agree that you will (in whole and in part):</p>
        <br />
        <ul>
          <li>
            Not rent, lease, sub-license, loan, provide, or otherwise make
            available, Artis Folio in any form, in whole or in part, to any
            person without prior written consent from us;
          </li>
          <br />
          <li>
            Not copy Artis Folio, in whole or in part, except as part of the
            normal use of Artis Folio or where it is necessary for the purpose
            of back-up or operational security;
          </li>
          <br />
          <li>
            Not translate, merge, adapt, vary, alter or modify the whole or any
            part of Artis Folio nor permit Artis Folio or any part of them to be
            combined with, or become incorporated in, any other programs or
            technology, except as necessary to use Artis Folio and the Services
            on devices as permitted in these Licence Terms;
          </li>
          <br />
          <li>
            Not disassemble, decompile, reverse engineer or create derivative
            works based on the whole or any part of Artis Folio, nor attempt to
            do any such things;
          </li>
          <br />
          <li>
            Not share your password or other login credentials with any other
            individual or allow access to your User Account; and
          </li>
          <br />
          <li>
            Not otherwise transfer Artis Folio (or any of the outputs or results
            of the Services) to anyone else, whether for money, for anything
            else or for free, unless expressly permitted under applicable law.
            We are giving you the right to access and use Artis Folio
            personally. If your Authorised Entity revokes your access to Artis
            Folio, reassigns your access in its entirety to another individual
            user, or if you sell any device on which Artis Folio is installed,
            you must remove Artis Folio from your device.
          </li>
        </ul>
        <br />
        <Typography level="h3">Acceptable use restrictions</Typography>
        <br />
        <p>
          You may only use Artis Folio for lawful internal business purposes of
          your Authorised Entity:
        </p>
        <br />
        <ul>
          <li>
            Not for the purpose of competing with our business (including
            offering the same or similar services that we offer or provide to
            any third party) or building a product or service that competes with
            Artis Folio without obtaining a prior written license to do so from
            us and/or our licensors; and
          </li>
          <br />
          <li>
            In compliance with all applicable laws, regulations, codes of
            conduct or otherwise, including without limitation data protection
            and privacy laws, laws relating to copyright of content and laws
            relating to unsolicited commercial electronic messages.
          </li>
        </ul>
        <br />
        <p>You agree you will not (in whole or in part):</p>
        <br />
        <ul>
          <li>
            Attempt to gain unauthorised access, interfere with, damage, or
            disrupt Artis Folio, or the server, equipment or network on which
            Artis Folio is stored or supplied;
          </li>
          <br />
          <li>
            Use phishing, screen scraping, data mining, robots, internet
            scamming, password robbery, spearing, or similar gathering,
            extraction tools, to collect any information from Artis Folio,
            including without limitation any trademarks, logos, or other
            proprietary information (including images, text, page layout, or
            form), email addresses, screen names or other identifiers, by deceit
            or otherwise, without our express written consent;
          </li>
          <br />
          <li>
            Use Artis Folio to distribute your own or a third party’s software
            or otherwise disseminate any information created using Artis Folio
            in breach of these Licence Terms, the Authorised Entity Agreements
            and the Exchange Agreements;
          </li>
          <br />
          <li>
            Carry out any denial-of-service attack or a distributed
            denial-of-service attack on Artis Folio, whether independently or in
            combination with Artis Folio, to carry out any such attack on any
            other website;
          </li>
          <br />
          <li>
            Allow anyone else, whether directly or indirectly, to access Artis
            Folio through your internet connection;
          </li>
          <br />
          <li>
            Reproduce, duplicate, copy, modify, create derivative works from,
            frame, mirror, republish, download, display, transmit, distribute or
            re-sell any part of Artis Folio in contravention of these Licence
            Terms, the Authorised Entity Agreements and the Exchange Agreements;
          </li>
          <br />
          <li>
            Attempt to de-compile, reverse compile, disassemble, reverse
            engineer, or otherwise reduce to human-perceivable form Artis Folio;
            and
          </li>
          <br />
          <li>
            Access, store, distribute or transmit any viruses, or any material
            during use of the Services that is unlawful, harmful, threatening,
            defamatory, obscene, infringing, harassing or racially or ethnically
            offensive, facilitates illegal activity, depicts sexually explicit
            images, promotes unlawful violence, is discriminatory based on race,
            gender, colour, religious belief, sexual orientation, disability or
            is otherwise illegal or causes damage or injury to any person or
            property.{" "}
          </li>
        </ul>
        <br />
        <p>
          You must not use Artis Folio, whether or not in conjunction with Artis
          Folio or otherwise (in whole or in part):
        </p>
        <br />
        <ul>
          <li>
            To attempt to gain unauthorised access to Artis Folio, any other
            website, internet account, server, computer, equipment, system,
            network, data or information;{" "}
          </li>
          <br />
          <li>
            In any way that breaches, in each case, any applicable law, national
            law, or international law;{" "}
          </li>
          <br />
          <li>
            In violation of export laws, controls, regulations or sanction
            policies of the United Kingdom or any other country worldwide;
          </li>
          <br />
          <li>
            In any way that is unlawful or fraudulent, or has any unlawful or
            fraudulent purpose or effect;{" "}
          </li>
          <br />
          <li>
            To send, transmit, publish, knowingly receive, upload, download,
            store, reproduce, use or re-use any material which does not comply
            with these Licence Terms, the Authorised Entity Agreements and the
            Exchange Agreements; or
          </li>
          <br />
          <li>
            To knowingly or recklessly introduce, transmit or permit the
            transmission of, send or upload to any data, application, or any
            material of any kind that contains viruses, ransomware, malware,
            trojan horses, worms, time-bombs, keystroke loggers, spyware, adware
            or any other harmful programs or similar computer code designed to
            adversely affect the operation of any computer software (including
            Artis Folio) or hardware.
          </li>
        </ul>
        <br />
        <Typography level="h3">User Account</Typography>
        <br />
        <p>
          If you register an account in order to access and use Artis Folio
          (“User Account”), you must provide accurate and complete registration
          information and keep that information up to date at all times.
        </p>
        <br />
        <p>
          Use of Artis Folio is username and password-protected. In order to use
          Artis Folio you will need a username identification email address,
          password and any pre-selected answers to security questions to access
          it. It is your responsibility to keep this information secret and
          confidential. You will be responsible for all uses of, and activity
          through, Artis Folio, including for the avoidance of doubt, the
          Services and Documentation available thereon, and in respect of each,
          accessed using your log-in credentials.
        </p>
        <br />
        <p>
          You agree to provide all additional information we may reasonably
          request and answer truthfully and completely any questions we might
          ask you to verify your identity.
        </p>
        <br />
        <p>
          You acknowledge and agree that you are only entitled to one account
          per person. Duplicate user profiles will be removed without further
          notice.
        </p>
        <br />
        <p>
          You expressly consent, upon acknowledging and accepting these Licence
          Terms during the account registration process (including any
          re-acceptance of our Licence Terms from time to time), we may record
          your personal data (including IP address) in order to verify the date,
          time and iteration of the Licence Terms accepted and which shall be
          legally binding.
        </p>
        <br />
        <p>
          If you think that somebody else might know your user identification
          code, password and/or pre-selected answers to security questions, then
          you must, as soon as possible, log in to your account, and change
          these, and notify us using the contact details set out in these
          Licence Terms.
        </p>
        <br />
        <p>
          We reserve the right to disable any user identification code, password
          and/or any pre-selected answers to security questions at any time if,
          in our reasonable opinion, you fail to comply with any of these
          Licence Terms and/or if we consider that you are using Artis Folio to
          access unauthorised content or infringe in any manner our rights, the
          rights of another person or if we consider that there may be a
          security risk.
        </p>
        <br />
        <Typography level="h3">Updates to Artis Folio </Typography>
        <br />
        <p>
          From time to time, we may automatically update any part of Artis Folio
          to improve performance, enhance functionality, reflect changes to
          Artis Folio or address any security issues. Alternatively, we may ask
          you to update Artis Folio for these reasons.
        </p>
        <br />
        <p>
          If you choose not to install such updates or if you opt out of
          automatic updates, you may not be able to continue using Artis Folio.
        </p>
        <br />
        <Typography level="h3">
          If someone else owns the device you are using
        </Typography>
        <br />
        <p>
          If you download Artis Folio onto any device not owned by you, you must
          have the owner's permission to do so. You will be responsible for
          complying with these Licence Terms, the Authorised Entity Agreements
          and Exchange Agreements whether or not you own the device.
        </p>
        <br />
        <Typography level="h3">
          We may collect technical data about your device
        </Typography>
        <br />
        <p>
          By using Artis Folio, you agree to us collecting and using technical
          information about the devices you use Artis Folio on, and the
          operating systems, related software, hardware and peripherals to
          improve Artis Folio and to provide any other services.
        </p>
        <br />
        <Typography level="h3">Feedback</Typography>
        <br />
        <p>
          Any feedback given by you to us is voluntary, and you grant us an
          unlimited, non-exclusive, perpetual, royalty-free license under your
          owned or controlled non-patent intellectual property rights to make,
          use, modify, distribute, and commercialise the feedback you give to us
          as relating to your use of Artis Folio, in whole or in part and
          without regard to whether such feedback is marked or otherwise
          designated by you as confidential.
        </p>
        <br />
        <Typography level="h3">Liability</Typography>
        <br />
        <p>
          Nothing in these Licence Terms limits or excludes our liability to you
          for:
        </p>
        <br />
        <ul>
          <li>death or personal injury arising from our negligence;</li>
          <br />
          <li>fraudulent misrepresentation or misrepresentation; and</li>
          <br />
          <li>
            any other matter for which we are not permitted by law to exclude or
            limit our liability, together (“Non-Excludable Liabilities”).
          </li>
        </ul>
        <br />
        <p>
          Subject to Non-Excludable Liabilities, we, the other members of our
          group of companies and any third parties connected to us hereby
          expressly exclude any liability for any direct, indirect, special, or
          consequential, punitive, or exemplary loss or damage arising out of or
          in connection with our failure to perform or defect or delay in
          performance of any of our obligations, your use of (or inability to
          use) Artis Folio or reliance upon or accuracy of any content or
          materials accessed via Artis Folio, including the outputs and results
          from the Services, and such excluded liability shall include but not
          limited to:
        </p>
        <br />
        <ul>
          <li>loss of income or revenue;</li>
          <br />
          <li>loss or interruption of business; </li>
          <br />
          <li>loss of profits or business;</li>
          <br />
          <li>loss of anticipated savings;</li>
          <br />
          <li>
            loss or corruption of data, corruption of data and/or remediation
            costs related to the loss or corruption of any data;
          </li>
          <br />
          <li>loss of goodwill;</li>
          <br />
          <li>
            any additional and/or wasted management or office time and/or
            administrative costs and expenses incurred by you arising from the
            availability of any of our websites or the Services; or
          </li>
          <br />
          <li>
            any losses, claims, demands, actions, costs, expenses or liabilities
            claimed from by a third party as a consequence of our breach of
            these Licence Terms;
          </li>
        </ul>
        <br />
        <p>
          however so caused, whether in contract, tort (including without
          limitation negligence or if caused by a deliberate and or repudiatory
          breach), misrepresentation, restitution, or otherwise, even if such
          losses are reasonably foreseeable.
        </p>
        <br />
        <p>
          Artis Folio provided by us or on our behalf by any of our group
          companies, partners or third parties in connection with Artis Folio
          and Services are provided on an 'as is' and 'as available' basis, and
          we make no representations or warranties of any kind, whether express
          or implied by law.{" "}
        </p>
        <br />
        <p>
          You expressly agree that your use of Artis Folio is at your sole risk,
          and we expressly disclaim any and all warranties, either express or
          implied by law (whether by statute, common law or otherwise),
          including without limitation warranties as to quality, suitability for
          any purpose, merchantability, compatibility, reliability, accuracy,
          completeness, availability, timeliness, access, use or
          non-infringement. Subject to the Non-Excludable Liabilities, we accept
          no responsibility or liability and make no guarantee that Artis Folio
          will be free from faults, errors and/or omissions. You are responsible
          for evaluating the quality, suitability, accuracy, completeness and
          reliability of the services or any information provided on or via any
          part of Artis Folio.
        </p>
        <br />
        <p>
          Subject to the Non-Excludable Liabilities, we disclaim all liability
          and responsibility arising from any unavailability at any time for any
          reason any part of Artis Folio or any reliance placed on any content
          or information provided by the Services, or by anyone who may be
          informed of the same.
        </p>
        <br />
        <p>
          We will not be liable for any loss or damage caused by a distributed
          denial-of-service attack, viruses or other technologically harmful
          material that may infect your computer equipment, computer programs,
          data or other proprietary material due to your use of Artis Folio in
          connection with it.
        </p>
        <br />
        <Typography level="h3">Indemnity</Typography>
        <br />
        <p>
          You will fully indemnify, defend and hold us and our affiliated
          companies, shareholders, members, officers, directors, managers,
          employees, agents or suppliers harmless from (i) any and all losses
          (including all direct, indirect and consequential losses),
          liabilities, costs (including legal costs), expenses, claims,
          proceedings, or demands, made by any third party, we suffer or incur,
          or will suffer or incur, due to or arising out of or in connection
          with your use of Artis Folio, (ii) the violation of these Licence
          Terms by you (including your obligations and warranties), or (iii) the
          infringement by you of any intellectual property or other right of any
          other person or entity.
        </p>
        <br />
        <Typography level="h3">Suspension and termination</Typography>
        <br />
        <p>
          We will determine, at our discretion, whether there has been a breach
          of these Licence Terms through your use of Artis Folio. When a breach
          of these Licence Terms has occurred, we may take such action as we
          deem appropriate.
        </p>
        <br />
        <p>
          Failure to comply with these Licence Terms constitutes a material
          breach of Licence Terms upon which you are permitted to use Artis
          Folio, and may result in our taking one, any, or all of the following
          actions:
        </p>
        <br />
        <ul>
          <li>
            Immediate, temporary, or permanent withdrawal of your right to use
            any part of Artis Folio.
          </li>
          <br />
          <li>
            Immediate, temporary, or permanent removal of any downloaded data,
            information or reports via your use of Artis Folio.
          </li>
          <br />
          <li>
            Issue of a written warning to you regarding any breach of these
            Licence Terms, including any obligations we require you to comply
            with as a result of the Authorised Entity Agreements and/or the
            Exchange Agreements.
          </li>
          <br />
          <li>
            Legal action as we consider appropriate, including legal proceedings
            against you for reimbursement of all costs on a full indemnity basis
            (including, but not limited to, reasonable administrative and legal
            costs) resulting from your breach of these Licence Terms.
          </li>
          <br />
          <li>Any further legal action against you.</li>
          <br />
          <li>
            Disclosure of such information to law enforcement authorities as we
            reasonably feel is necessary or is required by any applicable law.
          </li>
        </ul>
        <br />
        <p>
          If we withdraw your access or use of Artis Folio, you must, at our
          absolute discretion, return or destroy any copies of Artis Folio,
          including the outputs and results of the Services.
        </p>
        <br />
        <Typography level="h3">Data protection</Typography>
        <br />
        <p>
          You must keep all Personal Data (as defined by the UK GDPR) that you
          have access to and/or process confidential.
        </p>
        <br />
        <Typography level="h3">
          We may transfer these Licence Terms to someone else
        </Typography>
        <br />
        <p>
          We may transfer our rights and obligations under these Licence Terms
          to another organisation. We will always tell you in writing if this
          happens, and we will ensure that the transfer will not affect your
          rights under these Licence Terms.
        </p>
        <br />
        <Typography level="h3">No rights for third parties</Typography>
        <br />
        <p>
          This agreement does not give rise to any rights under the Contracts
          (Rights of Third Parties) Act 1999 to enforce any term of this
          agreement.
        </p>
        <br />
        <Typography level="h3">
          If a court finds part of these licence terms illegal, the rest will
          continue in force
        </Typography>
        <br />
        <p>
          Each of the paragraphs of these Licence Terms operates separately. If
          any court or relevant authority decides that any of its terms are
          unlawful, invalid or unenforceable, the remaining paragraphs will
          remain in full force and effect.
        </p>
        <br />
        <Typography level="h3">
          Even if we delay in enforcing these Licence Terms, we can still
          enforce it later
        </Typography>
        <br />
        <p>
          Even if we delay in enforcing these Licence Terms, we can still
          enforce them later. If we do not insist immediately that you do
          anything you are required to do under these Licence Terms, or if we
          delay in taking steps against you in respect of your breaching these
          Licence Terms, that will not mean that you do not have to do those
          things and it will not prevent us taking steps against you at a later
          date.
        </p>
        <br />
        <Typography level="h3">Entire agreement</Typography>
        <br />
        <p>
          These Licence Terms serve as our entire agreement with you on this
          subject and merges and supersedes all related prior and
          contemporaneous agreements.
        </p>
        <br />
        <Typography level="h3">Interpretation</Typography>
        <br />
        <p>
          Words imparting the singular number shall include the plural and
          vice-versa.
        </p>
        <br />
        <p>
          All references to “you” and “your” in these Licence Terms shall mean
          you as the authorised user of Artis Folio and services by and on
          behalf of your Authorised Entity.
        </p>
        <br />
        <Typography level="h3">Jurisdiction and applicable law</Typography>
        <br />
        <p>
          These Licence Terms or their subject matter or formation (and any
          non-contractual obligations arising out or in connection with them)
          are governed by the law of England and Wales, and the courts of
          England and Wales have non-exclusive jurisdiction to determine any
          dispute arising out of or in connection with them.{" "}
        </p>
        <br />
        <p>
          As a consumer, you will benefit from any mandatory provisions of the
          law of the country where you reside. Nothing in these Licence Terms
          affects your rights as a consumer to rely on such mandatory provisions
          of local country laws.
        </p>
        <br />
        <p>{`${username} - ${new Date().toISOString().slice(0, 10)}`}</p>
        <br />
        <p>
          <strong>
            BY CLICKING “ACCEPT” BELOW, YOU AGREE THAT THE FOLLOWING IS TRUE:
            (1) YOU REPRESENT THAT YOU HAVE ACTUAL AUTHORITY TO ENTER INTO THIS
            AGREEMENT ON BEHALF OF SUBSCRIBER; (2) THAT YOU HAVE READ THE TERMS
            STATED ABOVE; (3) YOU UNDERSTAND THE TERMS STATED ABOVE; (4) A
            PRINTOUT OF THE TERMS STATED ABOVE WILL CONSTITUTE A "WRITING" UNDER
            ANY APPLICABLE LAW OR REGULATION; AND (5) YOU AGREE TO ABIDE BY ALL
            THE TERMS OF THE AGREEMENT STATED ABOVE.
          </strong>
        </p>
      </div>
    </article>
  </>
);

const BuildCopy = ({
  agreementType,
  username,
  companyName,
}: TBuildCopyProps) => {
  const agreementComponents = {
    CME_NYMEX: <BuildCopyCME username={username} companyName={companyName} />,
    ICE_FUTURES_EUROPE: (
      <BuildCopyICEFutures username={username} companyName={companyName} />
    ),
    ICE_FUTURES_ABU_DHABI: (
      <BuildCopyICEFutures username={username} companyName={companyName} />
    ),

    ICE_ENDEX: (
      <BuildCopyICEEndex username={username} companyName={companyName} />
    ),
    IDS: <BuildCopyIDS username={username} companyName={companyName} />,
    TP_ICAP: (
      <BuildCopyTPIcapXC username={username} companyName={companyName} />
    ),
    ARTIS_XC: <BuildCopyArtisXC username={username} />,
  };

  return <Box>{agreementComponents[agreementType]}</Box>;
};

export const LicenceAgreementForm = ({
  username,
  agreementType,
  sourceId,
}: {
  username: string | undefined;
  agreementType: TAgreementType;
  sourceId: number | undefined;
}) => {
  const { data: org } = useCurrentUserOrg();
  const companyName = org?.organisation_by_pk?.sourceBySource.name;
  const [accepted, setAccepted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const userId = useUserId();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAccepted(event.target.checked);
  };

  const client = useApolloClient();

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setSubmitting(true);

    const newExchanges = [
      {
        folio_user: userId,
        source: sourceId,
        event: "start",
      },
    ];
    try {
      await client.mutate({
        mutation: amendExchangeUsage,
        variables: {
          exchangeUsages: newExchanges,
        },
      });
      await client.refetchQueries({
        include: ["exchangeUsages"],
      });

      setAccepted(false);
    } catch (error) {
      console.error("Error updating user:", error);
    } finally {
      setTimeout(() => {
        setSubmitting(false);
      }, 500);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box
        sx={{
          width: "100vw",
          height: "auto",
          minHeight: "100vh",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          position: "absolute",
          top: 0,
          left: 0,
          zIndex: licenseAgreementZIndex,
          background: "white",
          fontSize: "13px",
        }}
      >
        <Box
          sx={{
            width: "90vw",
            margin: "5vh 5vw",
          }}
        >
          <Box
            sx={{
              width: "130px",
              marginLeft: "-15px",
              marginBottom: "10px",
            }}
          >
            <ArtisLogo />
          </Box>

          <Box className="licence-agreements-top">
            <Box className="text-component">
              <BuildCopy
                agreementType={agreementType}
                username={username}
                companyName={companyName}
              />
              <FormControl>
                <Checkbox
                  id={"licence-agreement-checkbox"}
                  label={`ACCEPT ${agreementType.replace(/_/g, " ")} TERMS`}
                  name={agreementType}
                  checked={accepted}
                  onChange={handleChange}
                  color="primary"
                  size="sm"
                  sx={{
                    margin: "20px 0px",
                  }}
                />
              </FormControl>
            </Box>
          </Box>
          <Box className="licence-agreements-bottom">
            <Button
              variant="solid"
              type="submit"
              loading={submitting}
              disabled={submitting || !accepted}
            >
              {submitting ? "Submitting..." : "Submit"}
            </Button>
          </Box>
        </Box>
      </Box>
    </form>
  );
};
