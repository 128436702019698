import { Box } from "@mui/joy";
import { createRoute, lazyRouteComponent } from "@tanstack/react-router";
import { z } from "zod";
import { rootRoute } from "../baseRoutes";
import { Layout } from "./layout/Skeleton";

export const rootWebappRoute = createRoute({
  getParentRoute: () => rootRoute,
  path: "app",
  component: Layout,
});

export const marketSearchSchema = z.object({
  columnSettingsModal: z
    .object({
      columnId: z.string().optional(),
      initialColumnWidth: z.number().optional(),
      initialThousandsSeparator: z.boolean().optional(),
      initialDecimalPlaces: z.number().optional(),
      increment: z.number().optional(),
    })
    .optional(),
});

export const marketHomeRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "market",
  validateSearch: marketSearchSchema,
  component: lazyRouteComponent(() => import("./MarketView"), "MarketHome"),
});

export const marketPageRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "market/$id",
  validateSearch: marketSearchSchema,
  component: lazyRouteComponent(() => import("./MarketView"), "MarketPage"),
});

export const managePagesRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "manage-pages",
  component: () => <Box> manage pages </Box>,
});

export const tradingChartRoute = createRoute({
  getParentRoute: () => rootWebappRoute,
  path: "trading-chart",
  component: lazyRouteComponent(
    () => import("./live-charts/ChartsLayout"),
    "ChartsLayout",
  ),
});
