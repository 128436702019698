//import "./wydr";
import React from "react";
import ReactDOM from "react-dom/client";
import { App } from "./App";
import "./styles/common.css";
import { LicenseManager } from "ag-grid-enterprise";
import * as Sentry from "@sentry/react";
import { AG_KEY } from "./globals";
import posthog from "posthog-js";
import "jotai-devtools/styles.css";
import { DevTools } from "jotai-devtools";

posthog.init("phc_5VQrxO6QX946p5WIhQcFj6QJK5fsVbXn8iOsVsbhCh2", {
  capture_pageview: false,
  api_host: `${window.location.origin}/ingest`,
  ui_host: "https://eu.posthog.com",
  person_profiles: "identified_only",
});

Sentry.init({
  dsn: "https://fe87329662ff690016184c6cb335abb1@o4505007010414592.ingest.us.sentry.io/4506944634224640",
  integrations: [
    Sentry.browserProfilingIntegration(),
    Sentry.browserTracingIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  profilesSampleRate: 1.0,

  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost"],
});

LicenseManager.setLicenseKey(AG_KEY);

const rootEl = document.getElementById("root");

if (!rootEl) {
  throw new Error("No root element");
}

ReactDOM.createRoot(rootEl).render(
  <React.StrictMode>
    <DevTools position="bottom-right" />
    <App />
  </React.StrictMode>,
);
