import { useQuery } from "@triplit/react";
import { client } from "../../triplit/triplit";
import { useLoadingState } from "../sharedHooks";
import { useEffect } from "react";
import { useParams } from "@tanstack/react-router";
import { atom, useAtom, useAtomValue } from "jotai";
import type { TPage } from "../../triplit/schema";

export const pagesAtom = atom<TPage[] | null>(null);
export const pagesLoadedAtom = atom<boolean>(false);

export const allPagesQuery = client.query("pages").order("idx", "ASC");

export function useSubscribePages() {
  const {
    results,
    fetchingLocal: fetching,
    error,
  } = useQuery(client, allPagesQuery);

  const [pages, setPages] = useAtom(pagesAtom);
  const [finishedLoading, setLoaded] = useAtom(pagesLoadedAtom);

  useEffect(() => {
    if (!fetching) {
      setTimeout(() => {
        setLoaded(true);
      }, 1000);
    }
  }, [fetching, setLoaded]);

  useEffect(() => {
    if (results) {
      setLoaded(true);
      setPages(results);
    }
  }, [results, setPages, setLoaded]);

  return {
    fetching: !pages && !finishedLoading,
    results: pages,
    error,
  };
}

export function usePages() {
  const pages = useAtomValue(pagesAtom);
  const pagesLoading = useAtomValue(pagesLoadedAtom);
  return {
    results: pages,
    fetching: !pages && !pagesLoading,
    error: null,
  };
}

export function usePage(pageId?: string | null) {
  const { results, fetching, error } = usePages();
  const { setLoaded } = useLoadingState();
  const page = results?.find((p) => p.id === pageId);

  useEffect(() => {
    if (!fetching && !page) {
      setLoaded("market", "pageProductsLoading");
    }
  }, [fetching, page, setLoaded]);

  return {
    fetching,
    results: page,
    error,
  };
}

export function useFirstPageId() {
  const { results, fetching } = usePages();
  if (fetching) return null;
  return (results || [])[0]?.id || "no pages";
}

export function usePageId() {
  const params = useParams({ strict: false });
  const id = useFirstPageId();

  if ("id" in params) return params.id;
  return id;
}
