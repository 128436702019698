import { useAuth0 } from "@auth0/auth0-react";
import { parse } from "../numbers";
import {
  useDeepCompareMemo,
  useIntervalEffect,
  useThrottledCallback,
} from "@react-hookz/web";
import {
  QueryClient,
  type UseQueryOptions,
  useInfiniteQuery,
  useIsRestoring,
  useQueries,
  useQueryClient,
} from "@tanstack/react-query";
import type {
  PersistedClient,
  Persister,
} from "@tanstack/react-query-persist-client";
import { useEntity } from "@triplit/react";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import utc from "dayjs/plugin/utc";
import type {
  AddComponentOptions,
  DockviewApi,
  SerializedDockview,
} from "dockview";
import { del, get, set } from "idb-keyval";
import { atom, createStore, useAtom } from "jotai";
import {
  MismatchDirection,
  type ISeriesApi,
  type ITimeScaleApi,
  type OhlcData,
  type Time,
  type UTCTimestamp,
} from "lightweight-charts";
import { useCallback, useEffect, useMemo, useState } from "react";
import * as R from "remeda";
import type { TChartSettings } from "../../triplit/schema";
import { client } from "../../triplit/triplit";
import {
  type TSample,
  dockviewAtom,
  fromChartIdToIdx,
  genInitialFrom,
  parseQuestDBData,
  validSampleOrFirst,
  genInitialTo,
  getPreviousFromTo,
  sampleRoundingIndex,
  isDateRounded,
  findClosestPrice,
  getSpecificHistoricalDataDates,
  type TIndicatorsState,
  defaultIndicatorsState,
} from "./utils";
import roundPlugin from "./dayjsRoundPlugin";
import { useLoadingState } from "../sharedHooks";
import { calcWorker } from "../calculations-worker/hooks";
import type { TGridDataRowId } from "../calculations-worker/sharedStores";
import { monthCodeToOffset, monthStringToCode } from "../market-grid";
import {
  decompressIndicatorsState,
  parsedDockviewState,
} from "../../utils/compressedStringify";
import { defaultSelector } from "../market-grid/contextMenuHelpers";
import { useProductOptions } from "../components/ProductSelect";
import { graphql } from "../../graphql";
import { useQuery } from "@apollo/client";
import { z } from "zod";
import { isNullish } from "remeda";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(roundPlugin);

export function useChartProducts() {
  const products = useProductOptions({
    disableListPermission: true,
  });
  return products;
}

// const questQueryColumns = [
//   { name: "timestamp", type: "TIMESTAMP" },
//   { name: "open", type: "DOUBLE" },
//   { name: "close", type: "DOUBLE" },
//   { name: "min", type: "DOUBLE" },
//   { name: "max", type: "DOUBLE" },
// ] as const;

const questResponseSchema = z.array(
  z.array(
    z.tuple([z.string(), z.number(), z.number(), z.number(), z.number()]),
  ),
);

type TQuestResponse = z.infer<typeof questResponseSchema> | never[];

const infiniteQueryDataSchema = z.object({
  pages: z.array(
    z.object({
      data: questResponseSchema,
    }),
  ),
  pageParams: z.array(
    z.object({
      from: z.string(),
      to: z.string(),
      isRaw: z.optional(z.boolean()),
    }),
  ),
});

const regularQueryDataSchema = z.object({
  data: questResponseSchema,
  fromUTCDate: z.string(),
  toUTCDate: z.optional(z.string()),
  timeTaken: z.number(),
});

type TInfiniteQueryData = z.infer<typeof infiniteQueryDataSchema>;
type TRegularQueryData = z.infer<typeof regularQueryDataSchema>;

function select(data: TInfiniteQueryData) {
  const dataMap = new Map<number, ReturnType<typeof parseQuestDBData>[0]>();

  for (const page of data.pages) {
    if (page.data.length > 0) {
      const pageData = page.data[0] || [];
      const parsedPageData = parseQuestDBData(pageData);
      for (const item of parsedPageData) {
        if (item && item.time != null) {
          dataMap.set(item.time, item);
        }
      }
    }
  }

  const uniqueData = Array.from(dataMap.values());
  uniqueData.sort((a, b) => a.time - b.time);
  return uniqueData;
}

function selectRegular(data: TRegularQueryData) {
  if (data.data.length === 0) {
    return [];
  }

  return parseQuestDBData(data.data[0]);
}

const aggTableStartDate = dayjs("2024-08-29T07:20:00.000Z");

export function useSpecificHistoricalChartData({
  chartMetadata,
  dates,
}: {
  chartMetadata: TLiveChart;
  dates: Array<{
    from: string;
    to: string;
  }>;
}) {
  const { productId, periodFrom } = chartMetadata;
  const sampleTime = validSampleOrFirst(chartMetadata.sampleTime);

  if (!productId || !sampleTime) {
    throw new Error("productId and sampleTime are required");
  }

  const { round, unit } = sampleRoundingIndex[sampleTime];

  const { getAccessTokenSilently } = useAuth0();
  const artisType = chartMetadata.artisType || "sourced";
  const field = defaultSelector(artisType);
  const isCalc = field === "value";

  const queries = dates.map(({ from, to }, idx) => {
    const fromUTCDate = dayjs.utc(from).round(round, unit).toISOString();
    const toUTCDate = dayjs.utc(to).round(round, unit).toISOString();

    const queryKey = [
      "specificHistoricalChartData",
      productId,
      sampleTime,
      periodFrom,
      fromUTCDate,
    ];

    return {
      queryKey,
      enabled: !!(productId && sampleTime && chartMetadata.artisType),
      queryFn: async () => {
        const token = await getAccessTokenSilently();
        const params = {
          token,
          field,
          periodFrom: periodFrom || "",
          fromUTCDate,
          toUTCDate,
          product: productId,
          sampleTime: idx === 0 ? "15s" : sampleTime,
          isCalc,
          isRaw: idx === 0 ? false : !isDateRounded(toUTCDate),
        };

        const res = await fetchQuestData(params);

        if ((res.data.length && res.data?.[0]?.length) || idx !== 0) {
          return res;
        }

        let attempt = 1;

        while (attempt < 4) {
          const fromUTCDate = dayjs
            .utc(params.fromUTCDate)
            .subtract(attempt, "day")
            .toISOString();

          const toUTCDate = dayjs
            .utc(params.toUTCDate)
            .subtract(attempt, "day")
            .add(12, "hours")
            .toISOString();

          const res = await fetchQuestData({
            ...params,
            fromUTCDate,
            toUTCDate,
          });

          if (res.data.length && res.data?.[0]?.length) {
            return res;
          }

          attempt++;
        }
      },
      select: (data: unknown) => {
        const parsed = regularQueryDataSchema.safeParse(data);

        if (!parsed.success) {
          return [];
        }

        return selectRegular(parsed.data);
      },
    } satisfies UseQueryOptions;
  });

  return useQueries({
    queries,
  });
}

export function useHistoricalChartData({
  chartMetadata,
  setOldestDate,
  oldestDate,
}: {
  chartMetadata: TLiveChart;
  setOldestDate: (date: dayjs.Dayjs) => void;
  oldestDate: dayjs.Dayjs | null;
}) {
  const { productId, periodFrom } = chartMetadata;
  const sampleTime = validSampleOrFirst(chartMetadata.sampleTime);

  if (!productId || !sampleTime) {
    throw new Error("productId and sampleTime are required");
  }

  const { initialFrom, initialTo } = useMemo(() => {
    const initialFrom = genInitialFrom(sampleTime);
    const initialTo = genInitialTo(sampleTime);
    return { initialFrom, initialTo };
  }, [sampleTime]);

  const { getAccessTokenSilently } = useAuth0();
  const artisType = chartMetadata.artisType || "sourced";
  const field = defaultSelector(artisType);
  const isCalc = field === "value";
  return useInfiniteQuery({
    queryKey: ["historicalChartData", productId, sampleTime, periodFrom],
    meta: { persist: false },
    gcTime: 1000 * 60 * 60,
    initialPageParam: { from: initialFrom, to: initialTo, isRaw: false },
    enabled: !!(productId && sampleTime && chartMetadata.artisType),
    queryFn: async ({ pageParam }) => {
      const token = await getAccessTokenSilently();
      const res = fetchQuestData({
        token,
        field,
        periodFrom: periodFrom || "",
        fromUTCDate: pageParam.from,
        toUTCDate: pageParam.to,
        product: productId,
        sampleTime,
        isCalc,
        isRaw: pageParam.isRaw || !isDateRounded(pageParam.to) || false,
      });
      console.log("fetching historical data...", res, pageParam);
      return res;
    },
    getPreviousPageParam: (res) => {
      const fromUTCDate = res.fromUTCDate || res.data?.[0]?.[0]?.[0];
      if (!fromUTCDate) {
        return undefined;
      }
      const lastPageTime = dayjs.utc(fromUTCDate);

      const { from, to } = getPreviousFromTo(lastPageTime);
      if (from === to) return undefined;

      const fromDay = dayjs.utc(from);
      if (fromDay !== oldestDate) {
        setOldestDate(fromDay);
      }
      return { from, to, isRaw: fromDay.isBefore(aggTableStartDate) };
    },
    getNextPageParam: ({ toUTCDate }) => {
      const lastQuestDate = dayjs.utc(toUTCDate);
      const { round, unit } = sampleRoundingIndex[sampleTime];
      const latestCandleStart = dayjs.utc().round(round, unit);
      const roundedFrom = dayjs.utc(lastQuestDate).round(round, unit);
      if (latestCandleStart === roundedFrom || !toUTCDate) {
        console.log("no next page");
        return undefined;
      }
      const { from, to } = {
        from: roundedFrom.toISOString(),
        to: latestCandleStart.toISOString(),
      };
      if (from === to) return undefined;
      return { from, to, isRaw: true };
    },
    select,
  });
}

const oldestDataTimestamp = new Date("2024-05-22T15:00:33.423715Z");

type TFetchedData = {
  data: TQuestResponse;
  fromUTCDate: string;
  toUTCDate: string | null;
  timeTaken: number;
};

export async function fetchQuestData({
  fromUTCDate,
  toUTCDate,
  product,
  field,
  token,
  sampleTime,
  periodFrom,
  isCalc,
  isRaw,
  fn,
}: {
  fromUTCDate: string;
  toUTCDate: string | null;
  product: string;
  field: string;
  token: string;
  sampleTime: string;
  periodFrom: string;
  isCalc: boolean;
  isRaw: boolean;
  fn?: (res: Response) => unknown;
}) {
  if (toUTCDate && new Date(toUTCDate) < oldestDataTimestamp) {
    console.log("No more historical data to fetch.");
    return {
      data: [],
      fromUTCDate,
      toUTCDate,
      timeTaken: 0,
    } satisfies TFetchedData;
  }
  const queryUrl = "https://authz-service.artis.works/api/products";

  const prods = [
    {
      id: product,
      period_from: periodFrom,
      from: Date.parse(fromUTCDate),
      to: toUTCDate ? Date.parse(toUTCDate) : null,
      sample: sampleTime,
      shouldCache: !!toUTCDate,
      field,
      isCalc,
      isRaw,
    },
  ];

  const headers = new Headers({
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  });

  const url = new URL(queryUrl);
  url.searchParams.append("prods", JSON.stringify(prods));

  const options: RequestInit = {
    method: "GET",
    headers,
  };

  const startTime = performance.now();

  try {
    const res = await fetch(url.toString(), options);

    const endTime = performance.now();
    const timeTaken = endTime - startTime;

    if (!res.ok) {
      if (res.status === 503) {
        throw new Error("Service Unavailable");
      }
      throw new Error(`Failed to fetch data: ${res.statusText}`);
    }

    const resData = (await res.json()) as TQuestResponse;
    if (!R.isArray(resData)) {
      throw new Error("Invalid response data");
    }
    const candles = resData?.[0];
    if (!candles) throw new Error("Invalid response data2");
    const data = [candles] as TQuestResponse;
    console.log("Fetched data", data.length, {
      fromUTCDate,
      toUTCDate,
      timeTaken,
      resData,
      data,
    });

    if (fn) {
      fn(res);
    }

    if (toUTCDate) {
      console.log(`Request took ${timeTaken} milliseconds.`, {
        data,
      });
    }

    return {
      data,
      fromUTCDate,
      toUTCDate,
      timeTaken,
    } satisfies TFetchedData;
  } catch (error) {
    console.error("Failed to fetch data", error);
    throw error;
  }
}

type TLiveUpdatesTime = Record<string, string>;

export const liveUpdatesTimeAtom = atom<TLiveUpdatesTime>({});
liveUpdatesTimeAtom.debugLabel = "liveUpdatesTimeAtom";
export const liveUpdatesTimeStore = createStore();

export function genLiveUpdatesTimeKey({
  chartId,
  productId,
  sampleTime,
}: {
  chartId: string;
  productId: string;
  sampleTime: string;
}) {
  return `${productId}-${sampleTime}-${chartId}`;
}

export function storeLiveUpdatesTime({
  time,
  id,
}: {
  time: string;
  id: string;
}) {
  liveUpdatesTimeStore.set(liveUpdatesTimeAtom, (prev) => ({
    ...prev,
    [id]: time,
  }));
}

function mergeHistoricalAndLiveData(
  latest: Partial<OhlcData<Time>> | undefined,
  currentCandle: Partial<OhlcData<Time>> | undefined | null,
) {
  if (!currentCandle || !currentCandle.close) return latest || null;
  if (!latest) return currentCandle || null;
  const merged = {
    time: currentCandle.time,
    open: latest.open || currentCandle.open,
    close: currentCandle.close,
    high: latest?.high
      ? Math.max(latest.high, currentCandle?.high || latest.high)
      : currentCandle.high,
    low: latest?.low
      ? Math.min(latest.low, currentCandle?.low || latest.low)
      : currentCandle.low,
  };
  return merged;
}

export function useMergeHistoricalAndLiveData(
  historicalData: Partial<OhlcData>[] | undefined,
  currentCandle: OhlcData | undefined | null,
) {
  const data = useDeepCompareMemo(() => {
    if (!historicalData) return [];

    const latest = historicalData[historicalData.length - 1];

    const uniqueDataMap = new Map<number, OhlcData>();

    if (
      !!currentCandle &&
      historicalData.length > 0 &&
      currentCandle.time === latest.time
    ) {
      const merged = mergeHistoricalAndLiveData(latest, currentCandle);
      if (!merged) {
        historicalData.forEach((candle) => {
          if (candle && candle.time != null) {
            uniqueDataMap.set(Number(candle.time), candle as OhlcData);
          }
        });
        return Array.from(uniqueDataMap.values()).sort(
          (a, b) => Number(a.time) - Number(b.time),
        );
      }
      historicalData.slice(0, -1).forEach((candle) => {
        if (candle && candle.time != null) {
          uniqueDataMap.set(Number(candle.time), candle as OhlcData);
        }
      });
      uniqueDataMap.set(Number(merged.time), merged as OhlcData);
    } else {
      historicalData.forEach((candle) => {
        if (candle && candle.time != null) {
          uniqueDataMap.set(Number(candle.time), candle as OhlcData);
        }
      });
      if (currentCandle && currentCandle.time != null) {
        uniqueDataMap.set(Number(currentCandle.time), currentCandle);
      }
    }

    const uniqueData = Array.from(uniqueDataMap.values()).sort(
      (a, b) => Number(a.time) - Number(b.time),
    );

    return uniqueData;
  }, [historicalData, currentCandle]);

  return data as OhlcData[];
}

// to add the tick data to the chart
export function useLivelyUpdateMarketData({
  latestHistoricalCandle,
  chartMetadata,
  chart,
  fetchNextPage,
}: {
  latestHistoricalCandle: Partial<OhlcData> | undefined;
  chartMetadata: TLiveChart;
  chart: ISeriesApi<"Custom"> | null;
  fetchNextPage: () => void;
}) {
  const { artisType, productId, sampleTime, periodFrom } = chartMetadata;

  if (!productId || !sampleTime || !periodFrom) {
    throw new Error("productId and sampleTime are required");
  }
  const worker = calcWorker?.().proxy;

  const rowDate = periodFrom.slice(0, 10); // periodFrom is ISO string - this gets the date part
  const rowCode = monthStringToCode(rowDate);
  const rowIdx = rowCode && monthCodeToOffset(rowCode);
  const rowId = rowIdx && (["mth", rowCode, rowIdx] satisfies TGridDataRowId);
  const [currentCandle, setCurrentCandle] =
    useState<ReturnType<typeof mergeHistoricalAndLiveData>>(null);
  const columnId = `chart-${productId}-${periodFrom}-${sampleTime}`;
  useIntervalEffect(async () => {
    if (!rowId || !artisType) return;
    const data = await worker?.getGridData({
      rowIds: [rowId],
      columns: [
        {
          columnId,
          productId,
          eodId: null,
          artisType,
          hasSharedCell: artisType === "customer_curve",
          selector: defaultSelector(artisType),
          status: "listen",
          isPermissioned: true,
        },
      ],
    });
    const value = parse(data?.[0]?.[columnId]?.Ok);
    // roughly accounts for the latency of the data
    // this date is whats shown to the user in the blue box
    // so needs to roughly represent actual market ts
    // ideally this would come from a server but since
    // currently we're using the grid to get the data
    // we can't do that yet
    const date = dayjs().subtract(200, "millisecond");
    if (value) {
      storeLiveUpdatesTime({
        time: date.toString(),
        id: genLiveUpdatesTimeKey({
          chartId: chartMetadata.id,
          productId,
          sampleTime,
        }),
      });
    }
    const { round, unit } = sampleRoundingIndex[sampleTime];
    const currentCandleStart = date.round(round, unit);
    const time = (currentCandleStart.valueOf() / 1000) as UTCTimestamp;
    if (!value) {
      return {
        time,
      };
    }
    const prevCandleStart =
      currentCandleStart.subtract(round, unit).valueOf() / 1000;
    const latestHistoricalCandleTime = latestHistoricalCandle?.time || 0;
    if (
      latestHistoricalCandleTime &&
      latestHistoricalCandleTime !== prevCandleStart &&
      latestHistoricalCandleTime !== time
    ) {
      console.log("historical is stale", {
        prevCandleStart: dayjs(prevCandleStart * 1000).toISOString(),
        time: dayjs(time * 1000).toISOString(),
        latestHistoricalCandle: latestHistoricalCandle,
      });
      fetchNextPage();
    }
    setCurrentCandle((old) => {
      if (!value) return old;
      if (time !== old?.time) {
        return {
          time,
          open: value,
          close: value,
          high: value,
          low: value,
        };
      }
      const updated = {
        time,
        open: old.open,
        close: value,
        high: old?.high ? Math.max(value, old.high) : value,
        low: old?.low ? Math.min(value, old.low) : value,
      };
      if (latestHistoricalCandle?.time === updated.time) {
        return mergeHistoricalAndLiveData(latestHistoricalCandle, updated);
      }
      return updated;
    });

    if (chart && currentCandle) {
      const currentData = chart.dataByIndex(
        Number.MAX_SAFE_INTEGER,
        MismatchDirection.NearestLeft,
      );
      if (!currentData) return;
      const latestTimeInChart = currentData?.time as
        | UTCTimestamp
        | undefined
        | null;
      const candleTime = currentCandle.time as UTCTimestamp | undefined | null;
      if (
        !currentCandle.close ||
        !currentCandle.high ||
        !currentCandle.low ||
        !currentCandle.open ||
        !currentCandle.time
      ) {
        return;
      }

      const newCandle = {
        time: currentCandle.time || (0 as UTCTimestamp),
        open: currentCandle.open || 0,
        close: currentCandle.close || 0,
        high: currentCandle.high || 0,
        low: currentCandle.low || 0,
      };

      if (latestTimeInChart && candleTime && latestTimeInChart <= candleTime) {
        if (chart.data().length > 0) {
          chart.update(newCandle);
        } else {
          chart.setData([newCandle]);
        }
      }
    }
  }, 500);
  if (
    !currentCandle ||
    !currentCandle.close ||
    !currentCandle.high ||
    !currentCandle.low ||
    !currentCandle.open ||
    !currentCandle.time
  ) {
    return;
  }
  return currentCandle as OhlcData;
}

export function useResetChart(timeScale: ITimeScaleApi<Time> | null) {
  return useCallback(() => {
    console.log("resetting chart");
    timeScale?.resetTimeScale();
  }, [timeScale]);
}

// 2022-04-29
// without some hardcoded value for the historical limit
// it becomes very tricky to determine when to stop fetching
// without having the grid blow up due to messed up timestamps
const historicalLimitUnix = 1651190400;

export const chartFetchingAtom = atom(false);

export function useFetchHistoricalData({
  fetchPreviousPage,
  chart,
  timeScale,
  productId,
  sampleTime,
}: {
  fetchPreviousPage: ReturnType<typeof useHistoricalChartData>["fetchNextPage"];
  chart: ISeriesApi<"Custom"> | null;
  timeScale: ITimeScaleApi<Time> | null;
  productId: string | undefined;
  sampleTime: string | undefined;
}) {
  const client = useQueryClient();

  // we can't pass dependecies that change more often than productId or sampleTime
  // as it will cause the throttled callback to be reacreated and the
  // chart to jump back to the initial position.
  return useThrottledCallback(
    async () => {
      if (!productId || !sampleTime) return;

      const isFetching = client.isFetching({
        queryKey: ["historicalChartData", productId, sampleTime],
      });

      if (isFetching) {
        console.log("Already fetching");
        return;
      }

      const visibleRange = timeScale?.getVisibleLogicalRange();

      if (!chart || !visibleRange) return;

      const currentData = chart?.data();
      const firstEntryTime = currentData[0]?.time as UTCTimestamp | undefined;

      if (firstEntryTime && firstEntryTime <= historicalLimitUnix) {
        console.log("No more historical data to fetch.");
        return;
      }

      const bars = chart?.barsInLogicalRange(visibleRange);
      const numberOfBarsBeforeLeftEdge = bars?.barsBefore;

      if (currentData?.length) {
        const currentNumberOfBars = currentData.length;

        // issue with this is it doesn't count gaps. if you query e.g 5s at the weekend you won't get many if any bars depending on zoom level
        let minBarsInView = 50;
        if (sampleTime === "5s") {
          minBarsInView = 3000;
        } else if (sampleTime === "1m") {
          minBarsInView = 300;
        } else if (sampleTime === "5m") {
          minBarsInView = 100;
        }

        if (
          numberOfBarsBeforeLeftEdge &&
          numberOfBarsBeforeLeftEdge <= minBarsInView
        ) {
          console.log("Fetching...");

          const { data } = await fetchPreviousPage();

          console.log("From total bars:", currentNumberOfBars);
          console.log("To total bars:", data?.length);
        }
      }
    },
    [chart, productId, sampleTime],
    300,
  );
}

export function useChangeLayoutCharts() {
  const changeLayout = useCallback(
    (panels: number, dockview: DockviewApi | null) => {
      if (!dockview) throw new Error("No dockview api found");

      dockview.clear();

      const firstPanel = dockview.addPanel({
        id: "0",
        component: "default",
      });

      firstPanel.group.header.hidden = true;

      for (let i = 1; i < panels; i++) {
        let position: AddComponentOptions["position"];
        if (i === 1) {
          position = {
            direction: "right",
            referencePanel: "0",
          };
        } else if (i === 2) {
          position = {
            direction: "below",
            referencePanel: "0",
          };
        } else if (i === 3) {
          position = {
            direction: "below",
            referencePanel: "1",
          };
        }

        const panel = dockview.addPanel({
          id: i.toString(),
          component: "default",
          position,
        });

        panel.group.header.hidden = true;
      }
    },
    [],
  );
  return changeLayout;
}

export function useLiveChartsCache() {
  const [cache, setCache] = useState<PersistedClient>();
  const isRestoring = useIsRestoring();

  useEffect(() => {
    if (isRestoring) return;

    const checkCache = async () => {
      const cache = await persister.restoreClient();
      setCache(cache);
    };
    checkCache();
  }, [isRestoring]);

  return { cache, isRestoring, persister };
}

const artisTypeQuery = graphql(`
  query productArtisTypeById($id: uuid!) {
    product_by_pk(id: $id) {
      id
      artis_type
    }
  }
`);

export function useLiveChart(chartId: string) {
  const res = useEntity(client, "liveCharts", chartId);
  const [indicatorsState, setIndicatorsState] = useState<
    TIndicatorsState | undefined | null
  >(defaultIndicatorsState);
  const [isDecompressing, setIsDecompressing] = useState(false);

  const artisType = useQuery(artisTypeQuery, {
    variables: {
      id: res.result?.productId || "",
    },
    skip: !res.result?.productId,
  }).data?.product_by_pk?.artis_type;

  const decompressIndicators = useCallback(async (compressedState: string) => {
    setIsDecompressing(true);
    try {
      const decompressed = await decompressIndicatorsState(compressedState);
      if (decompressed) {
        setIndicatorsState(decompressed);
      }
    } catch (error) {
      console.error("Error decompressing indicators state:", error);
      setIndicatorsState(undefined);
    } finally {
      setIsDecompressing(false);
    }
  }, []);

  useEffect(() => {
    if (res.result?.indicatorsState) {
      decompressIndicators(res.result.indicatorsState);
    } else {
      setIndicatorsState(undefined);
      setIsDecompressing(false);
    }
  }, [res.result?.indicatorsState, decompressIndicators]);

  return useDeepCompareMemo(() => {
    if (
      !res.result ||
      !res.result?.productId ||
      !res.result?.sampleTime ||
      !res.result?.id
    ) {
      return {
        fetching: res.fetching,
      };
    }

    const sampleTime = res.result?.sampleTime as TSample | undefined;

    return {
      fetching: res.fetching || isDecompressing,
      results: {
        ...res.result,
        artisType,
        sampleTime,
        indicatorsState,
      },
    };
  }, [res, artisType, indicatorsState, isDecompressing]);
}
export type TLiveChart = NonNullable<
  ReturnType<typeof useLiveChart>["results"]
>;

export function useChartSettings() {
  const { user } = useAuth0();
  const userId = user?.sub;

  const { setLoaded } = useLoadingState();

  if (!userId) throw new Error("No userId in useChartSettings");

  const { result, fetchingLocal, error } = useEntity(
    client,
    "chartSettings",
    userId,
  );

  const [layoutState, setLayoutState] = useState<
    SerializedDockview | undefined | null
  >();

  useEffect(() => {
    if (result?.layoutState) {
      parsedDockviewState(result.layoutState).then(setLayoutState);
    }
  }, [result?.layoutState]);

  const chartSettings = useMemo(() => {
    if (!result) return undefined;

    return {
      ...result,
      layoutState,
      style: result?.style
        ? (JSON.parse(result.style) as TChartSettings["style"])
        : undefined,
    };
  }, [result, layoutState]);

  useEffect(() => {
    if (!fetchingLocal) {
      setLoaded("tradingChart", "chartSettings");
    }
  }, [fetchingLocal, setLoaded]);

  return {
    results: chartSettings,
    fetching: fetchingLocal,
    error,
  };
}

export function usePanelResize(id: string) {
  const idx = fromChartIdToIdx(id);
  const [dockview] = useAtom(dockviewAtom);
  const panelApi = dockview?.getPanel(idx)?.api;

  const [panelWidth, setPanelWidth] = useState<number | undefined>(
    panelApi?.width,
  );

  // to set new width when the user resizes internal panels
  useEffect(() => {
    dockview?.onDidLayoutChange(() => {
      setPanelWidth(panelApi?.width);
    });
  }, [dockview, panelApi]);

  const handleResize = useThrottledCallback(
    () => {
      setPanelWidth(panelApi?.width);
    },
    [panelApi],
    250,
  );

  // to set new panel width when the whole window resizes.
  // throttled to avoid unnecessary re-renders
  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  return {
    panelWidth,
  };
}

/**
 * Creates an Indexed DB persister
 * @see https://developer.mozilla.org/en-US/docs/Web/API/IndexedDB_API
 */
function createIDBPersister(idbValidKey: IDBValidKey = "reactQuery") {
  return {
    persistClient: async (client: PersistedClient) => {
      try {
        await set(idbValidKey, client);
      } catch (error) {
        if (
          error &&
          typeof error === "object" &&
          "message" in error &&
          typeof error.message === "string"
        ) {
          const match = error.message.match(
            /^Failed to execute 'put' on 'IDBObjectStore': (.*) could not be cloned\.$/,
          );
          if (!match) {
            console.error(error);
            return;
          }
          const valDesc = match[1];
          console.log("TODO handle valDesc", valDesc);
          console.error(error);
        }
      }
    },
    restoreClient: async () => {
      return await get<PersistedClient>(idbValidKey);
    },
    removeClient: async () => {
      await del(idbValidKey);
    },
  } as Persister;
}

export const persister = createIDBPersister();

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      gcTime: Number.POSITIVE_INFINITY,
    },
  },
});

type TSpecificPeriodLabel = "1d" | "7d" | "28d";

export type TPriceDifference = Record<
  TSpecificPeriodLabel | string,
  {
    date: Date | undefined;
    latestVisibleDate: Date | undefined;
    close: number | undefined;
    difference: number | undefined;
    className: string | undefined;
  }
>;

export function usePriceDifferences({
  currentCandle,
  historicalData,
  chartMetadata,
}: {
  currentCandle: OhlcData | undefined;
  historicalData: ReturnType<typeof useHistoricalChartData>["data"];
  chartMetadata: TLiveChart;
}) {
  const latestCandleTime = currentCandle?.time;

  const latestHistoricalData = useMemo(() => {
    return latestCandleTime
      ? currentCandle
      : findClosestPrice(Number(latestCandleTime), historicalData);
  }, [latestCandleTime, currentCandle, historicalData]);

  const periodDates = useMemo(() => {
    if (!latestCandleTime) return [];
    return getSpecificHistoricalDataDates(Number(latestCandleTime));
  }, [latestCandleTime]);

  const specificChartData = useSpecificHistoricalChartData({
    chartMetadata,
    dates: periodDates,
  });

  const priceDifferences = useMemo(() => {
    if (!latestHistoricalData) return {};

    return specificChartData.reduce((acc, curr, idx) => {
      const label = periodDates[idx].label;
      const targetTime = dayjs(periodDates[idx].from).unix();

      const data = findClosestPrice(targetTime, curr.data);

      const close = data?.close;
      const difference =
        !isNullish(latestHistoricalData?.close) && !isNullish(close)
          ? latestHistoricalData.close - close
          : undefined;

      acc[label] = {
        date: data?.time ? new Date(data.time * 1000) : undefined,
        latestVisibleDate: latestHistoricalData?.time
          ? new Date(Number(latestHistoricalData.time) * 1000)
          : undefined,
        close,
        difference,
        className: isNullish(difference)
          ? undefined
          : difference > 0
            ? "positive"
            : difference < 0
              ? "negative"
              : "",
      };
      return acc;
    }, {} as TPriceDifference);
  }, [specificChartData, periodDates, latestHistoricalData]);

  return priceDifferences;
}
