import { schema } from "./schema";
import { isDev } from "../globals";
import { TriplitClient, type ClientOptions } from "@triplit/client";

const serverUrl =
  import.meta.env.VITE_TRIPLIT_DB_URL ||
  "https://ca73a754-d523-4f7f-bcb4-c05467575558.triplit.io";

if (!serverUrl) {
  throw new Error("VITE_TRIPLIT_DB_URL is not set");
}

const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
const isAndroid = /android/i.test(navigator.userAgent);

const isMobile = isIOS || isAndroid;

export const triplitStorage = isMobile || isDev ? "memory" : "indexeddb";

const clientOpts = {
  schema,
  claimsPath: "triplitClaims",
  serverUrl: serverUrl,
  storage: triplitStorage,
} as const satisfies ClientOptions<typeof schema>;

export const client = new TriplitClient(clientOpts);
