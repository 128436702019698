import { SideNavIconContainer } from "./SideNavIconContainer";

function Icon({ selected }: { selected: boolean }) {
  return (
    <svg
      className={selected ? "nav-icon selected" : "nav-icon"}
      width="26"
      height="42"
      viewBox="0 0 1792 1792"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
    >
      <title>Help</title>
      <path
        d="M1008 1200v160q0 14-9 23t-23 9h-160q-14 0-23-9t-9-23v-160q0-14 9-23t23-9h160q14 0 23 9t9 23zm256-496q0 50-15 90t-45.5 69-52 44-59.5 36q-32 18-46.5 28t-26 24-11.5 29v32q0 14-9 23t-23 9h-160q-14 0-23-9t-9-23v-68q0-35 10.5-64.5t24-47.5 39-35.5 41-25.5 44.5-21q53-25 75-43t22-49q0-42-43.5-71.5t-95.5-29.5q-56 0-95 27-29 20-80 83-9 12-25 12-11 0-19-6l-108-82q-10-7-12-20t5-23q122-192 349-192 129 0 238.5 89.5t109.5 214.5zm-368-448q-130 0-248.5 51t-204 136.5-136.5 204-51 248.5 51 248.5 136.5 204 204 136.5 248.5 51 248.5-51 204-136.5 136.5-204 51-248.5-51-248.5-136.5-204-204-136.5-248.5-51zm768 640q0 209-103 385.5t-279.5 279.5-385.5 103-385.5-103-279.5-279.5-103-385.5 103-385.5 279.5-279.5 385.5-103 385.5 103 279.5 279.5 103 385.5z"
        className="orange"
      />
    </svg>
  );
}

export function HelpIcon({ selected = false }: { selected?: boolean }) {
  return (
    <SideNavIconContainer>
      <Icon selected={selected} />
    </SideNavIconContainer>
  );
}
