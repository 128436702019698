import type { ClientSchema, Entity } from "@triplit/client";
import { Schema as S } from "@triplit/db";
import type { SerializedDockview } from "dockview";
import type { ComponentProps } from "react";
import type { Chart } from "../webapp/live-charts";
import type { TIndicatorsState, TSample } from "../webapp/live-charts/utils";
import { z } from "zod";

export const schema = {
  // for org creation
  pendingOrganisation: {
    schema: S.Schema({
      id: S.Id(),
      orgId: S.Optional(S.Id()),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      updatedBy: S.String({
        nullable: true,
      }),
      name: S.String(),
      description: S.String({
        nullable: true,
      }),
      alias: S.String(),
      logo: S.String({
        nullable: true,
      }),
      allowedGlobalPackages: S.Boolean({
        nullable: true,
      }),
      needsHistoricalData: S.Boolean({
        nullable: true,
      }),
    }),
  },
  // for user creation
  pendingUser: {
    schema: S.Schema({
      id: S.Id(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      updatedBy: S.String({
        nullable: true,
      }),
      orgId: S.Id(),
      email: S.String({
        nullable: true,
      }),
      given_name: S.String({
        nullable: true,
      }),
      family_name: S.String({
        nullable: true,
      }),
      username: S.String({
        nullable: true,
      }),
      password: S.String({
        nullable: true,
      }),
    }),
  },
  userGroups: {
    schema: S.Schema({
      id: S.String(),
      displayName: S.String(),
      users: S.Set(S.String(), { nullable: true }),
    }),
  },
  pendingEditUser: {
    schema: S.Schema({
      id: S.Id(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      updatedBy: S.String({
        nullable: true,
      }),
      firstname: S.String({
        nullable: true,
      }),
      lastname: S.String({
        nullable: true,
      }),
      username: S.String({
        nullable: true,
      }),
      subscription_tier: S.Optional(
        S.String({
          nullable: true,
        }),
      ),
      roles: S.Set(S.String(), { nullable: true }),
      groups: S.Set(S.String(), { nullable: true }),
      blocked: S.Boolean({
        nullable: true,
      }),
    }),
  },
  pendingPackage: {
    schema: S.Schema({
      id: S.Id(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      updatedBy: S.String({
        nullable: true,
      }),
      orgId: S.Id(),
      name: S.String({ nullable: true }),
      source: S.Number({ nullable: true }),
      packageType: S.String({ nullable: true }),
      isGlobal: S.Boolean({ nullable: true }),
    }),
  },
  pendingReport: {
    schema: S.Schema({
      id: S.Id(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      updatedBy: S.String({
        nullable: true,
      }),
      eventDate: S.Date(),
    }),
  },
  auditLog: {
    schema: S.Schema({
      id: S.Id(),
      createdAt: S.Date(),
      succeededAt: S.Date({
        nullable: true,
      }),
      retries: S.Number({
        default: 0,
      }),
      actionName: S.String(),
      description: S.String({
        nullable: true,
      }),
      clearanceLevel: S.Optional(S.String()),
      userId: S.Id(),
      status: S.String({
        nullable: true,
      }),
      orgId: S.String({
        nullable: true,
      }),
      userGroup: S.String({
        nullable: true,
      }),
    }),
  },
  pendingPermission: {
    schema: S.Schema({
      id: S.Id(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      updatedBy: S.String({
        nullable: true,
      }),
      package: S.String(),
      userId: S.String(),
      permission: S.String(),
    }),
  },

  gridSettings: {
    schema: S.Schema({
      id: S.Id(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      extraHeaderHeight: S.Optional(S.Number()),
      months: S.Optional(S.Number()),
      statusMap: S.Optional(S.String()),
      incrementMap: S.Optional(S.String()),

      mainMonthColumnWidth: S.Optional(S.Number()),
      // column settings

      commodityParentGroup: S.Optional(S.Boolean()),
      geographicalRegion: S.Optional(S.Boolean()),
      commodityGroup: S.Optional(S.Boolean()),
      package: S.Optional(S.Boolean()),
      description: S.Optional(S.Boolean()),
      source: S.Optional(S.Boolean()),
      fieldName: S.Optional(S.Boolean()),

      qtrs: S.Optional(S.Number()),
      qtrSwitch: S.Optional(S.Boolean()),
      qtrCurrent: S.Optional(S.Boolean()),
      hlvs: S.Optional(S.Number()),
      hlvCurrent: S.Optional(S.Boolean()),
      hlvSwitch: S.Optional(S.Boolean()),

      seasons: S.Optional(S.Number()),
      seasonCurrent: S.Optional(S.Boolean()),
      seasonSwitch: S.Optional(S.Boolean()),

      cals: S.Optional(S.Number()),
      calCurrent: S.Optional(S.Boolean()),
      calSwitch: S.Optional(S.Boolean()),

      adhocSpreadsRows: S.Optional(S.Number()),
      adhocSpreadsSwitch: S.Optional(S.Boolean()),

      enableFullMonthRowFeature: S.Optional(S.Boolean()),
      flashCellUpdates: S.Optional(S.Boolean()),
      alternatingRowColours: S.Optional(S.Boolean()),
      hideStatusRow: S.Optional(S.Boolean()),
      gridScratchpadSwitch: S.Optional(S.Boolean()),
      scratchpadMaxRows: S.Optional(S.Number()),
      scratchpadMaxCols: S.Optional(S.Number()),
      quarterBorders: S.Optional(S.Boolean()),
      sound: S.Optional(S.Boolean()),

      hideEOD: S.Optional(S.Boolean()),
      hideBroadcast: S.Optional(S.Boolean()),
      hidePrivate: S.Optional(S.Boolean()),
      hideGlobal: S.Optional(S.Boolean()),

      indicatorColour: S.Optional(S.String({ nullable: true })),
      headerColumnColour: S.Optional(S.String({ nullable: true })),
      subheaderColumnColour: S.Optional(S.String({ nullable: true })),
      broadcastColour: S.Optional(S.String({ nullable: true })),
      hybridColour: S.Optional(S.String({ nullable: true })),
      listenColour: S.Optional(S.String({ nullable: true })),
      eodColour: S.Optional(S.String({ nullable: true })),
      localColour: S.Optional(S.String({ nullable: true })),
      globalColour: S.Optional(S.String({ nullable: true })),

      eodDate: S.Optional(S.String({ nullable: true })),
    }),
  },
  pages: {
    schema: S.Schema({
      id: S.Id(),
      createdAt: S.Date({
        default: S.Default.now(),
      }),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      idx: S.Number(),
      userId: S.String(),
      page: S.String(), //name of page
      extraHeaderHeightEnabled: S.Optional(S.Boolean()),
      cellHighlights: S.Optional(S.String()),
      columnHighlights: S.Optional(S.String()),
      periodHighlights: S.Optional(S.String()),
      adhocSpreadsJSON: S.Optional(S.String()),
      conditionalFormattingRules: S.RelationMany("conditionalFormattingRules", {
        where: [["pageId", "=", "$id"]],
      }),
      pageProducts: S.RelationMany("pageProducts", {
        where: [["pageId", "=", "$id"]],
      }),
      spreaderState: S.RelationById("spreaderState", "$pageId"),
    }),
    rules: {
      read: {
        "page-belongs-to-user": {
          description: "User can only read pages that belong to them",
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  pageProducts: {
    schema: S.Schema({
      id: S.Id(),
      productId: S.Optional(S.String()),
      columnType: S.String(),
      columnWidth: S.Number(),
      columnFieldSelector: S.Optional(S.String()),
      idx: S.String(),
      userId: S.String(),
      pageId: S.String(),
      decimalPlaces: S.Optional(S.Number()),
      thousandsSeparator: S.Optional(S.Boolean()),
    }),
  },
  sharedCells: {
    schema: S.Schema({
      // month + product
      id: S.Id(),
      value: S.String(),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
    }),
  },
  liveCharts: {
    schema: S.Schema({
      id: S.Id(),
      createdAt: S.Date({
        default: S.Default.now(),
      }),
      updatedAt: S.Date({
        default: S.Default.now(),
      }),
      userId: S.String(),
      productId: S.Optional(S.String()),
      sampleTime: S.Optional(S.String()),
      periodFrom: S.Optional(S.String()),
      indicatorsState: S.Optional(S.String()),
    }),
    rules: {
      read: {
        "user-is-userId": {
          description: "User can only read live Charts that belong to them",
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  chartSettings: {
    schema: S.Schema({
      id: S.Id(),
      userId: S.String(),
      layout: S.Optional(S.Number()),
      layoutState: S.Optional(S.String()),
      style: S.Optional(S.String()),
    }),
  },
  tableState: {
    schema: S.Schema({
      // createStateTableId in src/triplit/id-factories.ts
      id: S.Id(),
      userId: S.String(),
      stateString: S.Optional(S.String()),
      quickFilter: S.Optional(S.String()),
    }),
    rules: {
      read: {
        "table-state-belongs-to-user": {
          description: "User can only read table states that belong to them",
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  tableColumnState: {
    schema: S.Schema({
      // createStateTableId in src/triplit/id-factories.ts
      id: S.Id(),
      userId: S.String(),
      stateString: S.Optional(S.String()),
    }),
    rules: {
      read: {
        "table-column-state-belongs-to-user": {
          description:
            "User can only read table column states that belong to them",
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  theme: {
    schema: S.Schema({
      id: S.Id(),
      lumiTheme: S.String({
        default: "light",
      }),
      webappTheme: S.String({
        default: "light",
      }),
      liveChartsTheme: S.String({
        default: "light",
      }),
    }),
    rules: {
      read: {
        "theme-belongs-to-user": {
          filter: [["id", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  gridCharts: {
    schema: S.Schema({
      id: S.Id(),
      idx: S.Number(),
      userId: S.String(),
      productId: S.Optional(S.String()),
      selector: S.Optional(S.String()),
      period: S.Optional(S.Number()),
    }),
    rules: {
      read: {
        "belongs-to-user": {
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  adminUserSettings: {
    schema: S.Schema({
      id: S.Id(), // userId
      starredOrgs: S.Set(S.String(), { nullable: true }),
    }),
    rules: {
      read: {
        "belongs-to-user": {
          filter: [["id", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  conditionalFormattingRules: {
    schema: S.Schema({
      id: S.Id(),
      rule: S.String(),
      limit: S.String(),
      priorityIdx: S.String(),
      bgColor: S.Optional(S.String({ nullable: true })),
      boldText: S.Boolean(),
      invertTextColor: S.Boolean(),
      note: S.String(),
      stopIfTrue: S.Boolean(),
      columnId: S.String(),
      rowId: S.String(),
      userId: S.String(),
      pageId: S.String(),
    }),
    rules: {
      read: {
        "conditional-formatting-rules-belongs-to-user": {
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  alerts: {
    schema: S.Schema({
      id: S.Id(),
      limit: S.String(),
      valueBelowLimit: S.Boolean(), // true if the alert is for a value below the limit
      note: S.String(),
      status: S.String(),
      triggeredAt: S.Date({ nullable: true }),
      sound: S.Optional(S.Boolean()),
      fieldSelector: S.Optional(S.String()),
      columnId: S.String(),
      productId: S.String(),
      recurring: S.Optional(S.Boolean()),
      rowId: S.String(),
      userId: S.String(),
      pageId: S.String(),
    }),
    rules: {
      read: {
        "alert-belongs-to-user": {
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  spreaderState: {
    schema: S.Schema({
      id: S.Id(),
      userId: S.String(),
      pageId: S.String(),
      rowIdx: S.Number(),
      rowData: S.Record({
        title: S.String(),
        productLeft: S.String(),
        from: S.String(),
        fromSelector: S.String(),
        operandLeft: S.String(),
        offsetLeft: S.Number(),
        opSelector: S.String(),
        productRight: S.String(),
        to: S.String(),
        toSelector: S.String(),
        operandRight: S.String(),
        offsetRight: S.Number(),
      }),
    }),
    rules: {
      read: {
        "belongs-to-user": {
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
  viewedAnnouncements: {
    schema: S.Schema({
      id: S.Id(),
      userId: S.String(),
      announcementId: S.String(),
      title: S.String(),
      viewedAt: S.Date({ default: S.Default.now() }),
    }),
  },
  quickAccess: {
    schema: S.Schema({
      id: S.Id(), // userId + shortcutNumber
      userId: S.String(),
      pageId: S.String(),
      columnId: S.String(),
      productId: S.Optional(S.String()),
      shortcutNumber: S.Number(), // i.e. ctrl + shift + 1 where 1 is the shortcutNumber
    }),
    rules: {
      read: {
        "quick-access-belongs-to-user": {
          filter: [["userId", "=", "$session.SESSION_USER_ID"]],
        },
      },
    },
  },
} as const satisfies ClientSchema;

export type TPendingOrganisation = Entity<typeof schema, "pendingOrganisation">;

export type TPendingUser = Entity<typeof schema, "pendingUser">;
export type TPendingEditUser = Entity<typeof schema, "pendingEditUser">;

export type TPendingPackage = Entity<typeof schema, "pendingPackage">;

export const statusMapSchema = z.record(z.string(), z.string()).optional();
export const incrementMapSchema = z.record(z.string(), z.number()).optional();

export type TStatusMap = z.infer<typeof statusMapSchema>;
export type TIncrementMap = z.infer<typeof incrementMapSchema>;

export type TGridSettings = Omit<
  Partial<Entity<typeof schema, "gridSettings">>,
  "statusMap" | "incrementMap"
> & {
  statusMap?: TStatusMap;
  incrementMap?: TIncrementMap;
};

export const spreaderStateRowDataSchema = z.object({
  title: z.string(),
  productLeft: z.string(),
  from: z.string(),
  fromSelector: z.string(),
  operandLeft: z.string(),
  offsetLeft: z.number(),
  opSelector: z.string(),
  productRight: z.string(),
  to: z.string(),
  toSelector: z.string(),
  operandRight: z.string(),
  offsetRight: z.number(),
});

export const spreaderStateSchema = z.object({
  id: z.string(),
  userId: z.string(),
  pageId: z.string(),
  rowIdx: z.number(),
  rowData: spreaderStateRowDataSchema.optional(),
});

export type TSpreaderStateRowData = z.infer<typeof spreaderStateRowDataSchema>;

export type TSpreaderState = z.infer<typeof spreaderStateSchema>;

export function parseSpreaderStateRowData(rowData?: unknown) {
  const parseResult = spreaderStateRowDataSchema.safeParse(rowData);
  if (parseResult.success) {
    return parseResult.data;
  }
  console.error("Invalid TSpreaderStateRowData", {
    rowData,
    parseResult,
  });
  throw new Error("Invalid TSpreaderStateRowData");
}

export function parseSpreaderState(state?: unknown) {
  const parseResult = spreaderStateSchema.safeParse(state);
  if (parseResult.success) {
    return parseResult.data;
  }
  console.error("Invalid TSpreaderState", {
    state,
    parseResult,
  });
  throw new Error("Invalid TSpreaderState");
}

export type TPage = Entity<typeof schema, "pages">;
export type TPageProduct = Entity<typeof schema, "pageProducts">;
export type TSharedCell = Entity<typeof schema, "sharedCells">;
export type TPageSettings = Entity<typeof schema, "pages">;

export type TLiveChart = Omit<
  Entity<typeof schema, "liveCharts">,
  "sampleTime" | "indicatorsState"
> & {
  sampleTime: TSample;
  indicatorsState: TIndicatorsState;
};

export type TLiveChartStyle = Pick<
  ComponentProps<typeof Chart>,
  "layout" | "grid"
> & {
  candlestick: { upColor: string; downColor: string };
  mode: "light" | "dark";
};

export type TChartSettings = Omit<
  Entity<typeof schema, "chartSettings">,
  "style" | "layoutState"
> & {
  layoutState: SerializedDockview | undefined;
  style: TLiveChartStyle | undefined;
};
export type TChartLayout = Entity<typeof schema, "chartSettings">;
export type TTableState = Entity<typeof schema, "tableState">;
export type TTableColumnState = Entity<typeof schema, "tableColumnState">;
export type TTheme = Omit<
  Entity<typeof schema, "theme">,
  "lumiTheme" | "webappTheme"
> & {
  lumiTheme: "light" | "dark";
  webappTheme: "light" | "dark";
  liveChartsTheme: "light" | "dark";
};

export type TGridCharts = Entity<typeof schema, "gridCharts">;
export type TConditionalFormattingRules = Entity<
  typeof schema,
  "conditionalFormattingRules"
>;

export type TAlert = Entity<typeof schema, "alerts">;

export type TViewedAnnouncement = Entity<typeof schema, "viewedAnnouncements">;

export type TQuickAccess = Entity<typeof schema, "quickAccess">;
