import { useApolloClient } from "@apollo/client";
import { Box, useColorScheme } from "@mui/joy";
import { useIntervalEffect } from "@react-hookz/web";
import type {
  CellEditRequestEvent,
  GetRowIdFunc,
  GridOptions,
  GridReadyEvent,
} from "ag-grid-community";
import "ag-grid-community/styles/ag-grid.css"; // Core CSS
import "ag-grid-community/styles/ag-theme-quartz.css"; // Theme
import { AgGridReact } from "ag-grid-react";
import { memo, useCallback, useMemo, useRef } from "react";
import { useUserId } from "../../auth";
import type { TData } from "../calculations-worker/sharedStores";
import {
  bottomBarHeight,
  bottomPanelHeight,
  gridSettingsTransitionMatch,
  rowHeight,
  separatorHeight,
  topBarHeight,
} from "../globals";
import { useGridSettingsVisibility } from "../grid-settings";
import { useCurrentPageColDefs, usePageLoadingState } from "../market-pages";
import { parse } from "../numbers";
import { store } from "../sharedHooks";
import { colParams } from "../utils";
import {
  ColumnHeaderProduct,
  ColumnHeaderSelectMemo,
  ColumnHeaderStatus,
  ColumnMonthTimespread,
  ColumnMonths,
  TooltipProduct,
} from "./AgGridComponents";
import { useContextMenuItems } from "./contextMenuHelpers";
import { updateGrid } from "./livepricesHelpers";
import AddCurvesToPage from "./modals/AddCurvesToPage";
import { GridModalWrapper } from "./modals/GridModal";
import {
  onColumnMovedCallback,
  onColumnResizedCallback,
} from "./resizingDraggingEvents";
import { isMobile, useGridApi } from "../../shared/hooks";
import { getManualInstrumentType } from "./statuses/statusLogic";
import { statusMapAtom } from "../grid-settings/atomStore";
import { calcWorker } from "../calculations-worker/hooks";
import { processDataFromClipboard } from "./copyRangeSelectionHelpers";
import { useRangeSelectionChanged } from "./useRangeSelectionChange";
import { NumberCellEditor } from "./AgNumberCellEditor";
import { requestDockviewModal } from "./modals/modalComponents";
import { mainMonthColumnId } from ".";
import { useAdhocSpreads } from "./modals/timespreadHooks";
import {
  type TUndoRedo,
  handleCellKeyDown,
  undoRedoAtom,
  handleUndo,
  handleRedo,
} from "./keyboardShortcuts";
import { LoadingOverlay } from "../components/Loading";
import { useAtom } from "jotai";

function NoCurvesBox() {
  const loadingState = usePageLoadingState();

  return (
    <Box
      sx={{
        display: loadingState === "empty" ? "flex" : "none",
        justifyContent: "center",
        alignItems: "center",
        height: `calc(100dvh - ${topBarHeight}px - ${bottomBarHeight}px)`,
      }}
    >
      <AddCurvesToPage allowCancel={false} />
    </Box>
  );
}

export function MarketAgGrid({
  isBottomPanelOpen,
}: {
  isBottomPanelOpen: boolean;
}) {
  const graphqlClient = useApolloClient();

  const { formattedAdhocSpreads } = useAdhocSpreads();

  useIntervalEffect(() => {
    updateGrid(graphqlClient, formattedAdhocSpreads || []);
  }, 250);
  const isDark = useColorScheme().mode === "dark";
  const { left } = useGridSettingsVisibility();

  return (
    <>
      <NoCurvesBox />
      <Grid isBottomPanelOpen={isBottomPanelOpen} isDark={isDark} left={left} />
    </>
  );
}

MarketAgGrid.whyDidYouRender = true;

const Grid = memo(GridRaw);

function GridRaw({
  isBottomPanelOpen,
  isDark,
  left,
}: {
  isBottomPanelOpen: boolean;
  isDark: boolean;
  left: number;
}) {
  const [undoRedo, setUndoRedo] = useAtom(undoRedoAtom);

  const gridRef = useRef<AgGridReact<TData> | null>(null);

  const { proxy: calculationWorker } = calcWorker?.() || {};

  const gridCustomComponents = useMemo(() => {
    return {
      agColumnHeaderProducts: memo(ColumnHeaderProduct),
      agColumnHeaderDropdown: ColumnHeaderSelectMemo,
      agColumnHeaderStatus: memo(ColumnHeaderStatus),
      agColumnMonths: memo(ColumnMonths),
      agColumnMonthTimespread: memo(ColumnMonthTimespread),
      agTooltipProduct: memo(TooltipProduct),
      agNumberCellEditor: NumberCellEditor,
    };
  }, []);

  const userId = useUserId();
  const { setApi } = useGridApi();
  const resolvedBottomPanelHeight = isBottomPanelOpen ? bottomPanelHeight : 0;
  const gridHeight = `calc(100dvh - ${topBarHeight}px - ${bottomBarHeight}px - ${resolvedBottomPanelHeight}px)`;

  const onGridPreDestroyed = useCallback(() => {
    setApi(null);
  }, [setApi]);

  const onGridReadyCallback = useCallback(
    (params: GridReadyEvent) => {
      console.log("Grid ready");
      setApi(params.api);
    },
    [setApi],
  );

  const onCellEditRequestCallback = useCallback(
    (params: CellEditRequestEvent) => {
      const id = params.data?.id.toString();

      if (!id) return;

      const statusMap = store.get(statusMapAtom);

      const columnParams = colParams(params.column);
      const columnId = params.column.getColId();
      const productId = columnParams?.productId;

      if (!productId || !columnId) return;

      const status = statusMap?.[productId || ""];

      const storageType = getManualInstrumentType(status);
      if (!storageType) return;

      const newValue = params.newValue;
      const offset = params.rowIndex;
      const monthString = params.data?.monthString;

      if (!monthString)
        throw new Error(
          "month cannot be undefined in onCellEditRequestCallback",
        );

      const offsetIsNotSet = offset !== 0 && !offset;

      if (offsetIsNotSet || newValue === params.oldValue) {
        console.warn("Invalid cell edit request", {
          offset,
          oldValue: params.oldValue,
        });
        return;
      }
      const value = parse(newValue);

      const existingUndoPasted = undoRedo.redo.find((item) => item.pasted);

      const optimisticCellObject = {
        product: productId,
        offset,
        field: "value",
        storageType,
        month: monthString,
        rowId: id,
        pasted: params.source === "paste",
      } satisfies Omit<TUndoRedo, "result">;

      // If the last paste was more than 1 second ago, assume it's a new paste operation.
      const currentTime = new Date().getTime();

      // If the existing undos have a pasted item, it means more than one cell value was changed by a paste operation. So we append data to the existing undo items.
      if (existingUndoPasted && currentTime - undoRedo.time < 1000) {
        setUndoRedo((prevValue) => {
          return {
            undo: [
              ...prevValue.undo,
              { ...optimisticCellObject, result: params.oldValue },
            ],
            redo: [
              ...prevValue.redo,
              { ...optimisticCellObject, result: value ?? undefined },
            ],
            time: new Date().getTime(),
          };
        });
      } else {
        setUndoRedo({
          undo: [{ ...optimisticCellObject, result: params.oldValue }],
          redo: [{ ...optimisticCellObject, result: value ?? undefined }],
          time: new Date().getTime(),
        });
      }

      calculationWorker?.optimisticCellEdit([
        {
          ...optimisticCellObject,
          result: value ?? undefined,
        },
      ]);
    },
    [calculationWorker?.optimisticCellEdit, undoRedo, setUndoRedo],
  );

  const getRowId: GetRowIdFunc = useCallback((params) => {
    return params.data.id;
  }, []);

  const contextMenuItems = useContextMenuItems({
    isHeaderMenu: false,
  });

  const gridOptionsMemo = useMemo(() => {
    return {
      getRowHeight: (params) => {
        if (params.data?.period) {
          const firstRow = params.data?.firstOfType;
          const isNotMonth = params.data?.rowType !== "mth";

          return firstRow && isNotMonth
            ? rowHeight + separatorHeight
            : rowHeight;
        }
      },
      loadingOverlayComponent: LoadingOverlay,
      noRowsOverlayComponent: LoadingOverlay,
      undoRedoCellEditing: true,
      undoRedoCellEditingLimit: 5,
      onUndoStarted: handleUndo,
      onRedoStarted: handleRedo,
      onCellKeyDown: handleCellKeyDown,
      rowClassRules: {
        borderRow: (params) => {
          const isHlvAndH2Period =
            params.data?.rowType === "hlv" &&
            params.data?.period?.includes("H2");
          const isQtrAndQ4Period =
            params.data?.rowType === "qtr" &&
            params.data?.period?.includes("Q4");
          return !!(
            params.data?.year ||
            params.data?.quarter ||
            isHlvAndH2Period ||
            isQtrAndQ4Period
          );
        },
        separator: (params) => {
          const notMonth = params.data?.rowType !== "mth";
          const firstRow = params.data?.firstOfType;
          return firstRow && notMonth;
        },
      },
      processDataFromClipboard,
    } satisfies GridOptions;
  }, []);

  const onRangeSelectionChanged = useRangeSelectionChanged();

  const colDefs = useCurrentPageColDefs();
  return (
    <div
      id="marketGrid"
      className={
        isDark
          ? "ag-theme-quartz-dark ag-theme-dark-custom"
          : "ag-theme-quartz ag-theme-light-custom"
      }
      style={{
        height: gridHeight,
        left,
        position: "relative",
        transition: gridSettingsTransitionMatch,
      }}
    >
      {!isMobile && <GridModalWrapper gridHeight={gridHeight} />}
      <AgGridReact<TData>
        columnDefs={colDefs}
        defaultColDef={{
          sortable: false,
        }}
        ref={gridRef}
        tooltipShowDelay={500}
        getRowId={getRowId}
        scrollbarWidth={8}
        readOnlyEdit
        onCellEditRequest={onCellEditRequestCallback}
        rowHeight={rowHeight}
        gridOptions={{
          ...gridOptionsMemo,
          onCellClicked: (params) => {
            const isMonthColumn =
              params.column.getColId() === mainMonthColumnId;
            const isAdhoc = params.data?.rowType === "adhoc";

            if (isMonthColumn && isAdhoc) {
              const height = isMobile ? window.innerHeight : 320;
              const width = isMobile ? window.innerWidth : 450;

              requestDockviewModal({
                parentPanel: "tspds",
                offsetX: 20,
                offsetY: -100,
                params: {
                  height,
                  width,
                  rowId: params.data?.id,
                },
              });
            }
          },
        }}
        onGridReady={onGridReadyCallback}
        onGridPreDestroyed={onGridPreDestroyed}
        enableCharts
        suppressDragLeaveHidesColumns
        suppressColumnMoveAnimation
        suppressCsvExport
        suppressHeaderFocus
        suppressModelUpdateAfterUpdateTransaction
        animateRows={false}
        enableFillHandle
        enterNavigatesVertically
        enterNavigatesVerticallyAfterEdit
        onColumnMoved={onColumnMovedCallback}
        onColumnResized={(params) => onColumnResizedCallback(params, userId)}
        enableRangeSelection
        columnMenu={"new"}
        loading={false}
        suppressMenuHide={true}
        suppressRowHoverHighlight
        onRangeSelectionChanged={onRangeSelectionChanged}
        suppressRowVirtualisation={
          import.meta.env.VITE_CONTEXT !== "production"
        }
        components={gridCustomComponents}
        getContextMenuItems={contextMenuItems}
      />
    </div>
  );
}

//GridRaw.whyDidYouRender = true;
