import { atom } from "jotai";
import { z } from "zod";

let env =
  typeof window === "undefined"
    ? typeof process === "undefined"
      ? { DEV: "true" }
      : process.env
    : import.meta.env;

export function setEnv(newEnv: typeof env) {
  env = newEnv;
}

export function triplitServerUrl() {
  if (typeof env.VITE_TRIPLIT_DB_URL === "string") {
    return env.VITE_TRIPLIT_DB_URL;
  }
  throw new Error("VITE_TRIPLIT_DB_URL is not set");
}

export const isDev = env.DEV === "true" || env.MODE === "development";

// must use this from the worker as env is not available in the normal way
export const workerIsDev = () => env.MODE === "development";

// Global Definitions
export const SENTRY_DSN =
  "https://bd593a3a3051c2ccb39b3f1471819177@o4505007010414592.ingest.sentry.io/4505691086520320";
export const LOGTAIL_KEY = "CtiESwx98SffQ2Ph3nhpyRdS";

export const AG_KEY =
  "Using_this_{AG_Grid}_Enterprise_key_{AG-055592}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Artis_Works_Ltd}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{Artis_Folio}_only_for_{2}_Front-End_JavaScript_developers___All_Front-End_JavaScript_developers_working_on_{Artis_Folio}_need_to_be_licensed___{Artis_Folio}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{26_April_2025}____[v3]_[01]_MTc0NTYyMjAwMDAwMA==bb529487249db032048a74989801cb5c";

export const GIT_SHA = "NONE";
export const RELEASE_TIME = "NONE";
export const ENV_NAME = "NONE";
export const CONTEXT = "NONE";

export const AUTH0_CLIENT_ID =
  env.VITE_AUTH0_CLIENT_ID || "e7bR2BuJBNFRacmoWWEnWsd2RJnusOeC";
export const AUTH0_DOMAIN =
  env.VITE_AUTH0_DOMAIN || "https://auth.dev.artis.works";

// DEV Environment
export const DEV_HASURA_URL = "https://dev-folio-db.hasura.app/v1/graphql";
export const DEV_AUTH0_USERS_URL = "https://auth.dev.artis.works";
export const DEV_AUTH0_GLOBAL_ORG_ID = "org_3R60rCG9HWYwPpC0";

// UAT Environment
export const UAT_HASURA_URL = "https://uat.hasura.app/v1/graphql";
export const UAT_AUTH0_USERS_URL = "https://artisworks.eu.auth0.com";
export const UAT_AUTH0_GLOBAL_ORG_ID = "org_uajc8Wr0rLFlqXz8";

// PROD Environment
export const GLOBAL_PROD_HASURA_URL =
  "https://artis-staging.hasura.app/v1/graphql";
export const PROD_AUTH0_USERS_URL = "https://artisfoliostaging.eu.auth0.com";
export const AUTH0_GLOBAL_ORG_ID = "org_kUN3WbpsHzp0hbX6";

// TPICAP Environment
export const TPICAP_PROD_HASURA_URL =
  "https://prod-folio-tpicap.hasura.app/v1/graphql";
export const TPICAP_AUTH0_USERS_URL = "https://artisfoliostaging.eu.auth0.com";
export const AUTH0_TPICAP_ORG_ID = "org_XYwWBaz6vrPdxjeW";

export const envSchema = z.enum(["dev", "uat", "global-Prod", "TPICAP-Prod"]);

export const ORG_TO_HASURA_URL = {
  [DEV_AUTH0_GLOBAL_ORG_ID]: DEV_HASURA_URL,
  [UAT_AUTH0_GLOBAL_ORG_ID]: UAT_HASURA_URL,
  [AUTH0_GLOBAL_ORG_ID]: GLOBAL_PROD_HASURA_URL,
  [AUTH0_TPICAP_ORG_ID]: TPICAP_PROD_HASURA_URL,
} as const;

export type TEnv = z.infer<typeof envSchema>;

export const UserOrgAtom = atom<TOrgId | null>(null);
UserOrgAtom.debugLabel = "UserOrgAtom";

export function getHasuraUrl(env: TEnv) {
  const map = {
    dev: DEV_HASURA_URL,
    uat: UAT_HASURA_URL,
    "global-Prod": GLOBAL_PROD_HASURA_URL,
    "TPICAP-Prod": TPICAP_PROD_HASURA_URL,
  } as const;
  const url = map[env];
  if (url) {
    return url;
  }
  throw new Error(`No url found for env: ${env}`);
}

export function getHasuraUrlByOrg(orgId: keyof typeof ORG_TO_HASURA_URL) {
  const url = ORG_TO_HASURA_URL[orgId];

  if (!url) throw new Error(`No Hasura URL found for Org ID: ${orgId}`);

  return url;
}

export function getHasuraName(env: TEnv) {
  const url = getHasuraUrl(env);
  const regex = /https:\/\/([^.]+)\.hasura\.app/;
  const match = url.match(regex);
  return match ? match[1] : null;
}

export const getAuthOrgId = (env: TEnv) => {
  const map = {
    dev: DEV_AUTH0_GLOBAL_ORG_ID,
    uat: UAT_AUTH0_GLOBAL_ORG_ID,
    "global-Prod": AUTH0_GLOBAL_ORG_ID,
    "TPICAP-Prod": AUTH0_TPICAP_ORG_ID,
  } as const;
  const orgId = map[env];
  if (orgId) {
    return orgId;
  }
  throw new Error(`No orgId found for env: ${env}`);
};

export function orgIdToEnv(orgId: TOrgId) {
  const map = {
    [DEV_AUTH0_GLOBAL_ORG_ID]: "dev",
    [UAT_AUTH0_GLOBAL_ORG_ID]: "uat",
    [AUTH0_GLOBAL_ORG_ID]: "global-Prod",
    [AUTH0_TPICAP_ORG_ID]: "TPICAP-Prod",
  } as const;
  const env = map[orgId];
  if (env) {
    return env;
  }
  throw new Error(`No env found for orgId: ${orgId}`);
}

// Styles
// Note that changing anything in this file will cause a full reload of the app
// due to the way vite works so better not to put anything in here that changes
// often unless its really shared all over the app.
export const headerHeight = 65;

export const roles = [
  "no-broadcast",
  "excel-add-in",
  "scratchpad-beta",
  "read-only",

  "user",
  "manager",
  "umi-internal-write",
  "umi-internal-admin",
];

export const reportsStoreId = "reportsStore";

export const tableTopToolbarHeight = 50;

export type StringOrType<T> = T | (string & {});

export type ResObject<E = unknown> = {
  id: string;
  error: E | null;
};

export type TServerResponse<E = unknown> = ResObject<E>[] | undefined | null;

export const clearanceLevelsArray = [
  "user",
  "manager",
  "umi-internal-write",
  "umi-internal-admin",
  "umi-internal-super-admin",
] as const;

export const levels = {
  user: clearanceLevelsArray[0],
  manager: clearanceLevelsArray[1],
  internal: clearanceLevelsArray[2],
  admin: clearanceLevelsArray[3],
  superAdmin: clearanceLevelsArray[4],
};

export type HasuraClaims = {
  "x-hasura-allowed-roles": string[];
  "x-hasura-default-role": string;
  "x-hasura-org-id": string;
  "x-hasura-user-email": string;
  "x-hasura-user-id": string;
};

type TOrgId =
  | typeof DEV_AUTH0_GLOBAL_ORG_ID
  | typeof UAT_AUTH0_GLOBAL_ORG_ID
  | typeof AUTH0_GLOBAL_ORG_ID
  | typeof AUTH0_TPICAP_ORG_ID;

export type JWT = {
  "https://hasura.io/jwt/claims": HasuraClaims | null;
  iss: string;
  sub: string;
  aud: string[];
  iat: number;
  exp: number;
  azp: string;
  scope: string;
  org_id: TOrgId;
  sid: string;
};

export const NO_COLOR = "inherit";
